<template>
  <ValidationObserver ref="wizardPage1" id="wizardPage1" name="wizardPage1">
    <div v-if="appData">
      <claim-summary
        :app-data="appData"
        :ref-data="refData"
        :is-read-only="isReadOnly"
        v-on="$listeners"
      />

      <claim-policy-summary
        :app-data="appData"
        :ref-data="refData"
        :is-read-only="isReadOnly"
        v-on="$listeners"
      />

      <claim-alert
        :app-data="appData"
        :is-read-only="isReadOnly"
        v-on="$listeners"
      />

      <claim-follow-up :app-data="appData" :is-read-only="isReadOnly" />

      <instructions :instructions-text="instructions" />

      <div>
        <claim-contacts
          v-if="
            appData.strataCompany.contacts &&
            appData.strataCompany.contacts.length > 0
          "
          title="Strata Contacts"
          contact-type="Strata Company"
          :crm-entity="contactsEntity"
          :all-contacts="appData.strataCompany.contacts"
          :app-data="appData"
          :ref-data="refData"
          :allow-primary-selection="true"
          :is-read-only="isReadOnly"
          v-on="$listeners"
        />
        <div v-else class="no-data-message">
          <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
            <h4
              class="font-weight-bold text-dark pb-2"
              v-b-toggle="'contacts-panel'"
            >
              <i class="fas fa-user-circle fa-md text-primary"></i>
              <span class="ml-2" style="vertical-align: center">{{
                title || "Contacts"
              }}</span>
            </h4>
            No Strata Contacts assigned to
            {{ appData.strataCompany.Name || "Strata Company" }}.
          </b-card>
        </div>
      </div>
      <Document
        class="mt-10"
        document-name="Claim Form"
        :document-data="documentData"
        :on-create-document="createClaimForm"
        :processing-indicator="creatingClaimForm"
        :download-enabled="false"
        :is-read-only="isReadOnly"
        :is-editable="isClaimFormEditable"
      />

      <div
        class="form-group mt-4"
        v-if="
          !isReadOnly &&
          !appData.statusFlags.formReceived &&
          appData.documents.claimForm.id
        "
      >
        <button
          type="button"
          class="btn btn-primary"
          @click.prevent="getClaimFormToClientEmailTemplate"
        >
          <i class="fa fa-envelope"></i>
          Email Claim Form
        </button>
      </div>

      <ValidationProvider
        :rules="{ 'required-action': { allowFalse: false } }"
        name="K_ClaimFormStatus"
        v-slot="{ errors }"
      >
        <v-radio-group
          v-model="appData.claim.K_ClaimFormStatus"
          @change="saveClaimFormStatus"
        >
          <v-radio
            v-for="(status, index) in claimFormStatuses"
            :key="index"
            :label="status"
            :value="status"
            class="switch-radio"
            :disabled="isReadOnly"
          ></v-radio>
        </v-radio-group>
        <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
      </ValidationProvider>

      <div
        v-if="
          appData.claim.K_ClaimFormStatus && !appData.statusFlags.formReceived
        "
      >
        <div class="mb-6" style="max-width: 40rem">
          <b-card border-variant="primary" body-class="px-4 py-4" class="mt-6">
            <h6 class="font-weight-bold text-dark">
              <i class="fas fa-solid fa-spinner fa-spin text-primary"></i>
              <span class="ml-2" style="vertical-align: center"
                >Claim Form Status</span
              >
            </h6>

            <label class="mt-2 font-weight-bold"
              ><span class="text-warning"
                ><strong>Awaiting Claim Form submission.</strong></span
              ></label
            >
            <br />
            <button
              class="btn btn-primary text-center mt-3"
              @click="$emit('refreshData')"
              :disabled="isReadOnly"
            >
              <i class="fas fa-sync text-light" title="Refresh"></i> Refresh
            </button>
          </b-card>
        </div>

        <!-- Hidden input to validate if claim form has been reviewed -->
        <ValidationProvider
          :rules="{ 'required-action': { allowFalse: false } }"
          name="formReceived"
          v-slot="{ errors }"
        >
          <input type="hidden" v-model="appData.statusFlags.formReceived" />
          <span v-if="errors.length > 0" class="error-message">
            Claim form must be reviewed before proceeding.
          </span>
        </ValidationProvider>
      </div>

      <div v-if="appData.statusFlags.formReceived">
        <div class="mb-6" style="max-width: 40rem">
          <b-card border-variant="primary" body-class="px-4 py-4" class="mt-6">
            <h6 class="font-weight-bold text-dark">
              <i class="fas fa-solid fa-check text-primary"></i>
              <span class="ml-2" style="vertical-align: center"
                >Claim Form Status</span
              >
            </h6>

            <label class="mt-2 font-weight-bold"
              ><span class="text-primary">
                <strong
                  >Form received on
                  {{
                    appData.claim.K_DateClaimFormReceived
                      | dateParse("YYYY-MM-DD")
                      | dateFormat("DD/MM/YYYY")
                  }}.</strong
                >
              </span></label
            >
          </b-card>
        </div>

        <ValidationProvider
          :rules="{ 'required-action': { allowFalse: false } }"
          name="appData.statusFlags.claimFormReviewed"
          v-slot="{ errors }"
        >
          <div class="row mt-10" v-if="appData.statusFlags.formReceived">
            <span
              class="switch switch-sm switch-outline switch-icon switch-primary ml-4"
            >
              <label class="check-review">
                <input
                  type="checkbox"
                  class="form-control"
                  :disabled="isReadOnly"
                  v-model="appData.statusFlags.formReviewed"
                  @change="setClaimFormReviewedStatus()"
                />
                <span></span>
              </label>
            </span>
            <label class="col-form-label font-size-h5 ml-2"
              >I confirm that I have reviewed the claim form and have the
              required information to lodge the claim with the insurer.</label
            >
          </div>
          <span v-if="errors.length" class="error-message">{{
            errors[0]
          }}</span>
        </ValidationProvider>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss">
.note-text {
  margin-top: 20px;
  color: red;
}

.note-text {
  font-size: 15px;
}

.switch-radio .v-input--selection-controls__ripple {
  margin: 2px 0;
  height: 24px;
  width: 40px;
  border-radius: 12px;
  position: absolute;
  display: inline-block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.switch-radio .v-input--selection-controls__ripple:before {
  border: 2px solid #95979d;
  background-color: transparent;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transform: unset;
}
.switch-radio .v-input--selection-controls__ripple:after {
  content: "\f108";
  height: 20px;
  width: 20px;
  top: 2px;
  left: 0;
  bottom: 2px;
  margin-left: 2px;
  font-size: 0.55em;
  text-align: center;
  vertical-align: middle;
  position: absolute;
  border-radius: 50%;
  background: #ebedf3;
  transition: all 0.5s;
  font-family: ki,serif;
  color: #ebedf3;
  line-height: 2;
}
.switch-radio input:empty ~ span:before {
  border: 2px solid #ebedf3;
  background-color: transparent;
  transform: unset;
}

.switch-radio input:checked ~ .v-input--selection-controls__ripple:after {
  left: 16px;
  background: #3699ff;
}
.switch-radio .v-input--selection-controls__input {
  width: 50px;
}

.switch-radio .v-input--selection-controls__input i {
  display: none;
}

.switch-radio input:checked ~ .v-input--selection-controls__ripple:before {
  border-color: #3699ff;
  opacity: 1;
  background-color: transparent;
}
.switch-radio,
.v-input--selection-controls__input:hover,
.v-input--selection-controls__ripple:before {
  background-color: transparent;
  transform: unset;
}
.switch-radio .v-ripple__container {
  display: none;
}
</style>

<script>
import _ from "lodash";
import { mapState } from 'vuex';
import ClaimSummary from "../components/ClaimSummary";
import ClaimPolicySummary from "../components/ClaimPolicySummary";
import ClaimFollowUp from "../components/ClaimFollowUp.vue";
import Instructions from "../../../common/Instructions";
import ClaimAlert from "../components/ClaimAlert";
import ClaimContacts from "../components/ClaimContacts.vue";
import Document from "../../../common/Document.vue";
export default {
  name: "ReviewPage",

  components: {
    Document,
    ClaimAlert,
    ClaimSummary,
    ClaimPolicySummary,
    ClaimContacts,
    Instructions,
    ClaimFollowUp,
  },

  props: {
    appData: Object,
    refData: Object,
    isReadOnly: Boolean,
    hideButtons: Boolean,
  },

  data() {
    return {
      claimFormStatuses: [
        "Form Sent to Client/Strata Contact",
        "Form Completed on Client's/Strata Contact's Behalf",
        "Form Submitted by Client/Strata Contact",
      ],

      creatingClaimForm: false,
    };
  },

  mounted() {},

  methods: {
    createClaimForm: function () {
      this.creatingClaimForm = true;
      this.$claimDataService
        .createClaimForm()
        .then(({ data }) => {
          if (data.result) {
            // Update local appData
            this.$set(this.appData.documents.claimForm, "id", data.result.id);
            this.$set(this.appData.documents.claimForm, "view_url", data.result.view_url);
            this.$set(this.appData.documents.claimForm, "edit_url", data.result.edit_url);

            // Update Vuex store
            this.$store.commit('setClaimFormViewLink', data.result.view_url);
            this.$store.commit('setClaimFormInternalLink', data.result.edit_url);
            this.$store.commit('setInsurerNotified', false);

            // Emit event to update parent components
            this.$emit("updateWizardAppDataString");

            // Force update of the component
            this.$forceUpdate();
          }
          this.creatingClaimForm = false;
        })
        .catch((error) => {
          this.$emit("handleError", error);
          this.creatingClaimForm = false;
        });
    },

    getClaimFormToClientEmailTemplate: function () {
      // Check if primary-strata-contact has been selected
      if (!this.strataPrimaryContactSelected) {
        this.$emit(
          "handleError",
          "UI_ERROR: Please select a primary strata contact."
        );
        return;
      }

      this.$commonDataService
        .getEmailTemplate("CLAIM_FORM_TO_CLIENT")
        .then(({ data }) => {
          let emailTemplate = data.result;
          const url = window.URL.createObjectURL(new Blob([emailTemplate]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `cib-email-${new Date().getTime()}.eml`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$emit("handleError", error);
        });
    },

    saveClaimFormStatus: function () {
      let updatedData = {
        K_ClaimFormStatus: this.appData.claim.K_ClaimFormStatus,
      };
      this.$claimDataService
        .updateClaimData(updatedData)
        .then(({ data }) => {
          let result = _.get(data, "result");
          if (result && result.hasOwnProperty("K_Status")) {
            this.appData.statusFlags = result.statusFlags;
            this.appData.claim.K_Status = result.K_Status;
            this.appData.claim.K_NextStep = result.K_NextStep;
            this.$emit("updateGlobalClaimStatus");
          }
        })
        .catch((error) => {
          this.$emit("handleError", error);
        });
    },

    setClaimFormReviewedStatus: function () {
      if (!this.appData.statusFlags.formReviewed) {
        return;
      }
      let updatedData = { statusFlag: "formReviewed" };
      this.$claimDataService
        .updateClaimData(updatedData)
        .then(({ data }) => {
          let result = _.get(data, "result");
          if (result && result.hasOwnProperty("K_Status")) {
            this.appData.statusFlags = result.statusFlags;
            this.appData.claim.K_Status = result.K_Status;
            this.appData.claim.K_NextStep = result.K_NextStep;
            this.$emit("updateGlobalClaimStatus");
          }
        })
        .catch((error) => {
          this.$emit("handleError", error);
        });
    },
  },

  computed: {
    ...mapState({
      insurerNotified: state => state.insurerNotified,
      claimFormInternalLink: state => state.claimFormInternalLink,
      claimFormViewLink: state => state.claimFormViewLink,
    }),

    documentData() {
      return {
        id: this.appData.documents.claimForm.id,
        view_url: this.claimFormViewLink,
        edit_url: this.claimFormInternalLink
      };
    },

    contactsEntity: function () {
      return {
        type: "Strata Company",
        id: this.appData.strataCompany.id,
        name: this.appData.strataCompany.Name,
        link: this.strataCompanyCrmLink,
      };
    },

    strataCompanyCrmLink: function () {
      let strataCompany = _.get(this.appData, "strataCompany");
      if (strataCompany) {
        return `https://crm.zoho.com.au/crm/tab/${strataCompany.K_CustomModuleName}/${strataCompany.id}`;
      }
      return null;
    },

    instructions: function () {
      return (
        "Please review the Strata Contact details below to ensure they are correct.<br/><br/>" +
        "To associate/disassociate an existing Strata Contact, click on 'Select Contacts' and select/deselect the appropriate contact. " +
        "To add a new Strata Contact, click the 'Add New Contact' button. If the Strata Contact details are incorrect, these will need to be updated in CRM and the wizard refreshed to display the updated details. " +
        "Click on the Strata Company link below, click on the Contacts related list (left panel), edit and save the appropriate Contact record then refresh the wizard browser tab.<br/><br/>" +
        "Once the Strata Contact details are correct, create the Claim Form then send it to the client/strata manager."
      );
    },

    strataPrimaryContactSelected: function () {
      let claimContacts = _.get(this.appData, "claim.K_ClaimContacts");
      if (Array.isArray(claimContacts)) {
        return _.some(claimContacts, (contact) => {
          return (
            contact.K_ContactType === "Strata Company" &&
            contact.K_Primary === true
          );
        });
      }
      return false;
    },

    isClaimFormEditable() {
      return !this.insurerNotified && !!this.claimFormInternalLink;
    },

  },
};
</script>
