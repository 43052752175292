<template>
  <div>
    <b-card border-variant="primary" body-class="px-4 py-4" class="mb-10">
      <h4 class="font-weight-bold text-dark" v-b-toggle="'claim-policy-summary-panel'">
        <i class="fas fa-list-alt fa-md text-primary"></i>
        <span class="ml-2" style="vertical-align: center">Building & Policy Summary</span>

        <i v-if="!isExpanded" class="fas fa-angle-double-down fa-md float-right" title="Click to Expand"></i>
        <i v-if="isExpanded" class="fas fa-angle-double-up fa-md float-right" title="Click to Collapse"></i>
      </h4>

      <b-card body-class="px-2 py-2" class="mt-5 mb-5" v-if="
          appData &&
          appData.asset &&
          appData.asset.K_Alert &&
          !lossDateIsInPolicyPeriod
        ">
        <label class="mt-3 font-weight-bold text-dark">
          <i class="fas fa-exclamation-circle text-danger fa-md mr-1"></i>
          {{ appData.asset.K_Alert }}
        </label>
      </b-card>

      <b-collapse id="claim-policy-summary-panel" v-model="isExpanded">
        <div v-if="appData">
          <div v-if="!lossDateIsInPolicyPeriod" class="mt-5">
            <div class="form-group mb-1">
              <label class="text-danger font-weight-bold font-size-h5">
                Date of loss ({{
                appData.claim.K_DateOfLoss
                | dateParse("YYYY-MM-DD")
                | dateFormat("DD/MM/YYYY")
                }}) is not within the policy period. Please select a historical
                policy.<br />
                If the historical policy is not present in the CRM, use the '+'
                button to add a new record.
              </label>
            </div>
            <div class="row" v-if="isEditMode">
              <div class="col-10">
                <v-select id="id" v-model="newPolicyId" :items="availablePolicies" :item-text="getPolicyLabel"
                  item-value="id" label="Select Policy" clearable dense chips>
                </v-select>
              </div>
              <div class="col-2">
                <button class="btn btn-primary text-center" @click.prevent="openAddPolicyDialog">
                  <i class="fas fa-plus" title=""></i>
                </button>
              </div>
            </div>
            <div class="form-group">
              <button class="btn btn-primary col-2" v-if="!isReadOnly && !isEditMode"
                @click.prevent="editPolicySelection" :disabled="savingData">
                <i class="fas fa-exchange-alt"></i>
                Change Policy
              </button>
              <button class="btn btn-primary col-2" v-if="!isReadOnly && isEditMode"
                @click.prevent="savePolicySelection" :disabled="savingData">
                Save
              </button>
              <b-spinner class="ml-3 align-middle" variant="primary" v-if="savingData"></b-spinner>
              <button class="btn btn-secondary ml-3" v-if="!isReadOnly && isEditMode" @click.prevent="cancelEditMode"
                :disabled="savingData">
                Cancel
              </button>
            </div>
          </div>

          <!-- Hidden input to validate if date-of-loss is within the policy period -->
          <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }" name="lossDateIsInPolicyPeriod"
            v-slot="{ errors }">
            <input type="hidden" v-model="lossDateIsInPolicyPeriod" />
            <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
          </ValidationProvider>

          <!-- ROW 1 -->
          <div class="form-group row mb-2">
            <label class="col-2 col-form-label text-right">Plan Number</label>
            <label class="col-2 col-form-label font-weight-bold">
              <a target="_blank" :href="assetCrmLink">
                {{ appData.policy.K_PlanNumber }}
              </a>
            </label>
            <label class="col-2 col-form-label text-right">Building Address</label>
            <label class="col-2 col-form-label font-weight-bold">
              <span v-if="appData.asset.K_Address">
                {{ appData.asset.K_Address }}
                <a target="_blank" :href="assetAddressMapLink">
                  <i class="ml-2 fas fa-map-marker-alt fa-lg text-danger"></i>
                </a>
              </span>
            </label>
            <label class="col-2 col-form-label text-right">Building Name</label>
            <label class="col-2 col-form-label font-weight-bold">
              <a target="_blank" :href="assetCrmLink">{{
                appData.asset.Name
                }}</a>
            </label>
          </div>

          <!-- ROW 2 -->
          <div class="form-group row mb-2">
            <label class="col-2 col-form-label text-right">Policy Name</label>
            <label class="col-2 col-form-label font-weight-bold">
              <a :href="policyCrmLink" target="_blank">
                {{ appData.policy.Name }}
              </a>
            </label>
            <label class="col-2 col-form-label text-right">Policy Number</label>
            <label class="col-2 col-form-label font-weight-bold">
              {{ appData.policy.K_PolicyNumber }}
            </label>
            <label class="col-2 col-form-label text-right">Insurer</label>
            <label class="col-2 col-form-label font-weight-bold">
              <a :href="insurerCrmLink" target="_blank">
                {{ appData.policy.K_Insurer.name }}
              </a>
            </label>
          </div>

          <!-- ROW 3 -->
          <div class="form-group row mb-2">
            <label class="col-2 col-form-label text-right">Policy From Date</label>
            <label class="col-2 col-form-label font-weight-bold">
              <span v-if="appData.policy.K_FromDate">
                {{
                appData.policy.K_FromDate
                | dateParse("YYYY-MM-DD")
                | dateFormat("DD/MM/YYYY")
                }}
              </span>
            </label>
            <label class="col-2 col-form-label text-right">Policy To Date</label>
            <label class="col-2 col-form-label font-weight-bold">
              <span v-if="appData.policy.K_ToDate">{{
                appData.policy.K_ToDate
                | dateParse("YYYY-MM-DD")
                | dateFormat("DD/MM/YYYY")
                }}</span>
            </label>
            <label class="col-2 col-form-label text-right">Strata Company</label>
            <label class="col-2 col-form-label font-weight-bold">
              <a :href="strataCompanyCrmLink" target="_blank">
                {{ appData.strataCompany.Name }}
              </a>
            </label>
          </div>

          <!-- SUMS INSURED -->
          <declaration-form v-if="
              appData.policy.declarationData &&
              appData.policy.declarationData.sumInsuredData
            " :formData="appData.policy.declarationData.sumInsuredData" :configuration="sumsDataTableConfiguration"
            :is-read-only="true" />

          <!-- EXCESSES -->
          <declaration-form v-if="
              appData.policy.declarationData &&
              appData.policy.declarationData.excessData
            " :formData="appData.policy.declarationData.excessData" :configuration="excessDataTableConfiguration"
            :is-read-only="true" />

          <b-modal ref="addPolicyDialog" size="lg" no-close-on-backdrop centered hide-footer>
            <template #modal-title>
              <span>Add Historical Policy</span>
            </template>

            <div class="form-group" v-if="policyBeingAdded">
              <label class="col-form-label font-size-h4"><strong>Plan Number: {{ policyBeingAdded.K_PlanNumber
                  }}</strong></label>

              <ValidationObserver ref="addNewPolicyDialogData">
                <div class="row">
                  <div class="col-6">
                    <label class="col-form-label">Policy Number<span class="text-danger">*</span></label>
                    <ValidationProvider rules="required|max:255" name="K_PolicyNumber" v-slot="{ errors }">
                      <input class="form-control" maxlength="255" placeholder="Policy Number"
                        v-model="policyBeingAdded.K_PolicyNumber" />
                      <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-6">
                    <label class="col-form-label">Policy Type<span class="text-danger">*</span></label>
                    <input class="form-control" maxlength="255" v-model="policyBeingAdded.K_PolicyStatus" disabled />
                  </div>
                  <div class="col-6">
                    <label class="col-form-label">From Date<span class="text-danger">*</span></label>
                    <ValidationProvider rules="required" name="K_FromDate" v-slot="{ errors }">
                      <b-form-datepicker class="form-control" :locale="'en-AU'" v-model="policyBeingAdded.K_FromDate"
                        :date-format-options="{
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }"></b-form-datepicker>
                      <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-6">
                    <label class="col-form-label">To Date<span class="text-danger">*</span></label>
                    <ValidationProvider rules="required" name="K_ToDate" v-slot="{ errors }">
                      <b-form-datepicker class="form-control" :locale="'en-AU'" v-model="policyBeingAdded.K_ToDate"
                        :date-format-options="{
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        }"></b-form-datepicker>
                      <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-6">
                    <label class="col-form-label">Insurer<span class="text-danger">*</span></label>
                    <ValidationProvider rules="required" name="K_Insurer" v-slot="{ errors }">
                      <v-select v-model="policyBeingAdded.K_Insurer" :items="refData.allInsurers" item-text="Name"
                        return-object label="Select" clearable dense chips>
                      </v-select>
                      <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-6">
                    <label class="col-form-label">Strata Company<span class="text-danger">*</span></label>
                    <ValidationProvider rules="required" name="K_StrataCompany" v-slot="{ errors }">
                      <v-select v-model="policyBeingAdded.K_StrataCompany" :items="refData.allStrataCompanies"
                        item-text="Name" return-object label="Select" clearable dense chips>
                      </v-select>
                      <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="col-12 d-flex justify-content-end mt-4">
                    <div class="row">
                      <button type="submit" class="btn btn-primary" @click.prevent="addNewPolicy"
                        :disabled="savingData">
                        Submit
                      </button>
                      <b-spinner v-if="savingData" class="mt-2" variant="primary"></b-spinner>
                      <button type="reset" class="btn btn-danger ml-3" @click.prevent="cancelAddPolicyDialog"
                        :disabled="savingData">
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </ValidationObserver>
            </div>
          </b-modal>
        </div>
      </b-collapse>
    </b-card>
  </div>
</template>

<style lang="scss"></style>

<script>
import _ from "lodash";
import DeclarationForm from "../../../common/DeclarationForm.vue";
import moment from "moment-timezone";

export default {
  name: "ClaimPolicySummary",

  components: { DeclarationForm },

  props: {
    appData: Object,
    refData: Object,
    isReadOnly: Boolean,
  },

  data() {
    return {
      isExpanded: true,
      isEditMode: false,
      savingData: false,
      newPolicyId: null,
      policyBeingAdded: null,
      availablePolicies: [],
    };
  },

  mounted() {
    this.getAvailablePolicies();
  },

  methods: {
    editPolicySelection: function () {
      this.isEditMode = true;
    },

    savePolicySelection: function () {
      this.savingData = true;
      this.$claimDataService
        .updateClaimPolicy(this.newPolicyId)
        .then((/*{ data }*/) => {
          this.$emit("refreshWizardAppData", true /*showSpinner*/);
          this.isEditMode = false;
          this.savingData = false;
          this.getAvailablePolicies();
        })
        .catch((error) => {
          this.savingData = false;
          this.$emit("handleError", error);
        });
    },

    cancelEditMode: function () {
      this.isEditMode = false;
    },

    openAddPolicyDialog: function () {
      let fromDateMoment = moment(this.appData.policy.K_FromDate, "YYYY-MM-DD");
      let toDateMoment = moment(this.appData.policy.K_ToDate, "YYYY-MM-DD");
      let historicalFromDate = fromDateMoment
        .subtract(1, "year")
        .format("YYYY-MM-DD");
      let historicalToDate = toDateMoment
        .subtract(1, "year")
        .format("YYYY-MM-DD");

      this.policyBeingAdded = {
        K_PolicyNumber: this.appData.policy.K_PolicyNumber,
        K_FromDate: historicalFromDate,
        K_ToDate: historicalToDate,
        K_PolicyStatus: "Historical",
        K_PlanNumber: this.appData.policy.K_PlanNumber,
        K_Asset: { id: this.appData.asset.id },
      };
      this.$refs["addPolicyDialog"].show();
    },

    cancelAddPolicyDialog: function () {
      this.policyBeingAdded = {};
      this.$refs["addPolicyDialog"].hide();
    },

    addNewPolicy: function () {
      this.$refs.addNewPolicyDialogData.validate().then((isValid) => {
        if (isValid) {
          this.savingData = true;
          this.$claimDataService
            .addClaimPolicy(this.policyBeingAdded)
            .then((/*{ data }*/) => {
              this.$emit("refreshWizardAppData", true /*showSpinner*/);
              this.isEditMode = false;
              this.savingData = false;
              this.policyBeingAdded = {};
              this.getAvailablePolicies();
              this.$refs["addPolicyDialog"].hide();
            })
            .catch((error) => {
              this.savingData = false;
              this.$emit("handleError", error);
            });
        }
      });
    },

    getPolicyLabel: function (policy) {
      let fromDate = moment(policy.K_FromDate, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      );
      let toDate = moment(policy.K_ToDate, "YYYY-MM-DD").format("DD/MM/YYYY");
      return `${policy.Name} - ${policy.K_PolicyNumber} (${fromDate} to ${toDate})`;
    },

    getAvailablePolicies: function () {
      let availablePolicies = [];
      if (this.appData && Array.isArray(this.refData.assetPolicies)) {
        let claimPolicyId = this.appData.policy.id;

        availablePolicies = this.refData.assetPolicies.filter((pol) => {
          return pol.id !== claimPolicyId;
        });
      }
      this.availablePolicies = availablePolicies || [];
    },
  },

  computed: {
    lossDateIsInPolicyPeriod: function () {
      let dateOfLoss = _.get(this.appData, "claim.K_DateOfLoss");
      if (dateOfLoss) {
        let fromDateMoment = moment(
          this.appData.policy.K_FromDate,
          "YYYY-MM-DD"
        );
        let toDateMoment = moment(this.appData.policy.K_ToDate, "YYYY-MM-DD");
        let lossDateMoment = moment(dateOfLoss, "YYYY-MM-DD");

        return (
          lossDateMoment.isSameOrAfter(fromDateMoment) &&
          lossDateMoment.isSameOrBefore(toDateMoment)
        );
      }
      return true;
    },

    assetCrmLink: function () {
      let asset = _.get(this.appData, "asset");
      if (asset) {
        return `https://crm.zoho.com.au/crm/tab/${asset.K_CustomModuleName}/${asset.id}`;
      }
      return null;
    },

    policyCrmLink: function () {
      let policy = _.get(this.appData, "policy");
      if (policy) {
        return `https://crm.zoho.com.au/crm/tab/${policy.K_CustomModuleName}/${policy.id}`;
      }
      return null;
    },

    strataCompanyCrmLink: function () {
      let strataCompany = _.get(this.appData, "strataCompany");
      if (strataCompany) {
        return `https://crm.zoho.com.au/crm/tab/${strataCompany.K_CustomModuleName}/${strataCompany.id}`;
      }
      return null;
    },

    insurerCrmLink: function () {
      let insurer = _.get(this.appData, "policy.K_Insurer");
      if (insurer) {
        return `https://crm.zoho.com.au/crm/tab/${insurer.K_CustomModuleName}/${insurer.id}`;
      }
      return null;
    },

    assetAddressMapLink: function () {
      let address = _.get(this.appData, "asset.K_Address");
      if (address) {
        let suburb = _.get(this.appData, "asset.K_Suburb") || "";
        let state = _.get(this.appData, "asset.K_State") || "";
        let postcode = _.get(this.appData, "asset.K_Postcode") || "";
        address = `${address} ${suburb} ${state} ${postcode}`.trim();

        return `https://maps.google.com/?q=${address.replace(/ /g, "+")}`;
      }
      return null;
    },

    sumsDataTableConfiguration: function () {
      return {
        title: "Sums Insured",
        currentTitle: "Current Value",
        showNewValue: false,
      };
    },

    excessDataTableConfiguration: function () {
      return {
        title: "Excesses",
        currentTitle: "Current Value",
        showNewValue: false,
      };
    },
  },
};
</script>
