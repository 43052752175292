<template>
  <ValidationObserver ref="wizardPage4" id="wizardPage4" name="wizardPage4">
    <div>
      <div v-if="appData">
        <claim-summary :app-data="appData" :ref-data="refData" :is-read-only="true" v-on="$listeners" />

        <claim-policy-summary :app-data="appData" :ref-data="refData" :is-read-only="true" v-on="$listeners" />

        <claim-alert :app-data="appData" :is-read-only="isReadOnly" v-on="$listeners" />

        <claim-follow-up :app-data="appData" :is-read-only="isReadOnly" />

        <instructions :instructions-text="instructions" />

        <claim-damages :app-data="appData" :is-read-only="true" />

        <claim-settlements :app-data="appData" :is-read-only="true" @damages-updated="updateClaimDamages" />

        <div class="mt-10">
          <!--          <div class="form-group" v-if="!claimReportExists">-->
          <!--            <button class="btn btn-primary" :disabled="isReadOnly || creatingClaimReport"-->
          <!--                    @click.prevent="createClaimReport">Create Claim Finalisation Report</button>-->
          <!--            <b-spinner v-if="creatingClaimReport" class="ml-3 align-middle" variant="primary" label="Please wait..."></b-spinner>-->
          <!--          </div>-->
          <Document class="mt-10" :document-name="'Claim Finalisation Report'"
            :document-data="appData.documents.claimReport" :on-create-document="createClaimReport"
            :processing-indicator="creatingClaimReport" :recreate-enabled="
              !isReadOnly && !appData.statusFlags.claimReportSent
            " :is-read-only="isReadOnly">
          </Document>

          <!-- Hidden input to validate if the user created the claim report -->
          <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
            name="appData.documents.claimReport.id" v-slot="{ errors }">
            <input type="hidden" v-model="appData.documents.claimReport.id" />
            <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
          </ValidationProvider>

          <button type="button" class="btn btn-primary font-weight-bold px-4 py-3 mt-4"
            v-if="!isReadOnly && claimReportExists" @click.prevent="getClaimFinalisationEmailTemplate" :disabled="
              appData.statusFlags.claimReportSent || creatingClaimReport
            ">
            <i class="fa fa-envelope"></i>
            Email Claim Finalisation Report
          </button>

          <div v-if="claimReportExists">
            <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }"
              name="appData.statusFlags.claimReportSent" v-slot="{ errors }">
              <div class="row mt-10">
                <span class="switch switch-sm switch-outline switch-icon switch-primary ml-4">
                  <label>
                    <input type="checkbox" :disabled="isReadOnly" v-model="appData.statusFlags.claimReportSent"
                      @change="setClaimReportSentStatus()" />
                    <span></span>
                  </label>
                </span>
                <label class="col-form-label font-size-h5 ml-2">
                  I confirm that I have sent the Claim Finalisation Report.
                </label>
              </div>
              <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>
      </div>
    </div>
  </ValidationObserver>
</template>

<style lang="scss"></style>

<script>
import ClaimSummary from "../components/ClaimSummary";
import ClaimPolicySummary from "../components/ClaimPolicySummary";
import ClaimAlert from "../components/ClaimAlert";
import ClaimFollowUp from "../components/ClaimFollowUp.vue";
import Instructions from "../../../common/Instructions";
import ClaimDamages from "../components/ClaimDamages.vue";
import ClaimSettlements from "../components/ClaimSettlements.vue";
import Document from "../../../common/Document.vue";
import _ from "lodash";

export default {
  name: "FinalisationPage",

  components: {
    Document,
    ClaimSummary,
    ClaimPolicySummary,
    ClaimAlert,
    ClaimDamages,
    ClaimFollowUp,
    ClaimSettlements,
    Instructions,
  },

  props: {
    appData: Object,
    refData: Object,
    isReadOnly: Boolean,
  },

  data() {
    return {
      creatingClaimReport: false,
    };
  },

  computed: {
    instructions: function () {
      return "Please create the Claim Finalisation Report, and send it to the client after reviewing it.";
    },

    claimReportExists: function () {
      let claimReportLink = _.get(
        this.appData,
        "documents.claimReport.view_url"
      );
      return !!claimReportLink;
    },

  },

  methods: {
    updateClaimDamages(updatedDamages) {
      this.$set(this.appData, 'claimDamages', updatedDamages);
      this.$emit("updateWizardAppDataString");
    },

    createClaimReport: function () {
      this.creatingClaimReport = true;
      this.$claimDataService
        .createClaimReport()
        .then(({ data }) => {
          if (data.result) {
            let claimReportResult = data.result;
            this.appData.documents.claimReport = {
              id: claimReportResult.id,
              view_url: claimReportResult.K_ClaimFinalisationReportLink,
            };
            this.$emit("updateWizardAppDataString");
          }
          this.creatingClaimReport = false;
        })
        .catch((error) => {
          this.creatingClaimReport = false;
          this.$emit("handleError", error);
        });
    },

    getClaimFinalisationEmailTemplate: function () {
      this.$commonDataService
        .getEmailTemplate("CLAIM_FINALISATION")
        .then(({ data }) => {
          let emailTemplate = data.result;
          const url = window.URL.createObjectURL(new Blob([emailTemplate]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `cib-email-${new Date().getTime()}.eml`
          );
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          this.$emit("handleError", error);
        });
    },

    setClaimReportSentStatus: function () {
      if (!this.appData.statusFlags.claimReportSent) {
        return;
      }
      let updatedData = { statusFlag: "claimReportSent" };
      this.$claimDataService
        .updateClaimData(updatedData)
        .then(({ data }) => {
          let result = _.get(data, "result");
          if (result && result.hasOwnProperty("K_Status")) {
            this.appData.statusFlags = result.statusFlags;
            this.appData.claim.K_Status = result.K_Status;
            this.appData.claim.K_NextStep = result.K_NextStep;
            this.$emit("updateGlobalClaimStatus");
          }
        })
        .catch((error) => {
          this.$emit("handleError", error);
        });
    },
  },
  watch: {
    appData: {
        deep: true,
        handler(_) {
          this.$forceUpdate(); // Force a re-render
        }
      }
  },
};
</script>
