<template>
  <div>
    <b-card border-variant="primary" body-class="px-4 py-4" class="mb-10">
      <h4 class="font-weight-bold text-dark" v-b-toggle="'claim-summary-panel'">
        <i class="fas fa-list-alt text-primary fa-md"></i>
        <span class="ml-2" style="vertical-align: center">Claim Summary</span>

        <i v-if="!isExpanded" class="fas fa-angle-double-down fa-md float-right" title="Click to Expand"></i>
        <i v-if="isExpanded" class="fas fa-angle-double-up fa-md float-right" title="Click to Collapse"></i>
      </h4>

      <b-collapse id="claim-summary-panel" v-model="isExpanded">
        <div v-if="appData && refData">
          <ValidationObserver ref="claimSummaryData">
            <!-- ROW 1 -->
            <!--            <div class="form-group row mb-4">-->
            <!--              <label class="col-2 col-form-label text-right">Claim Name<span class="text-danger">*</span></label>-->
            <!--              <div class="col-10">-->
            <!--                <ValidationProvider rules="required|max:255" name="Name" v-slot="{ errors }">-->
            <!--                  <input class="form-control" maxlength="255" v-model="appData.claim.Name"-->
            <!--                         :disabled="isReadOnly || !isEditMode"-->
            <!--                  />-->
            <!--                  <span class="text-danger">{{ errors[0] }}</span>-->
            <!--                </ValidationProvider>-->
            <!--              </div>-->
            <!--            </div>-->

            <!-- ROW 2 -->
            <div class="form-group row mb-4">
              <label class="col-2 col-form-label text-right">Claim Type<span class="text-danger">*</span></label>
              <div class="col-2">
                <ValidationProvider rules="required" name="K_TypeOfClaim" v-slot="{ errors }">
                  <b-form-select class="form-control" v-model="appData.claim.K_TypeOfClaim"
                    :disabled="isReadOnly || !isEditMode">
                    <option :value="null">-- Select --</option>
                    <option value="Accidental Damage">Accidental Damage</option>
                    <option value="Burst Pipe">Burst Pipe</option>
                    <option value="Common Contents">Common Contents</option>
                    <option value="Earthquake">Earthquake</option>
                    <option value="Fidelity Guarantee">
                      Fidelity Guarantee
                    </option>
                    <option value="Fire">Fire</option>
                    <option value="Flood">Flood</option>
                    <option value="Fusion">Fusion</option>
                    <option value="Glass">Glass</option>
                    <option value="Government Audit Costs">
                      Government Audit Costs
                    </option>
                    <option value="Impact Damage">Impact Damage</option>
                    <option value="Legal Defence Expenses">
                      Legal Defence Expenses
                    </option>
                    <option value="Machinery Breakdown">
                      Machinery Breakdown
                    </option>
                    <option value="Malicious Damage/Vandalism/B&E Damage">
                      Malicious Damage/Vandalism/B&E Damage
                    </option>
                    <option value="Office Bearers Liability">
                      Office Bearers Liability
                    </option>
                    <option value="Public Liability Personal Injury">
                      Public Liability Personal Injury
                    </option>
                    <option value="Public Liability Material Damage">
                      Public Liability Material Damage
                    </option>
                    <option value="Storm">Storm</option>
                    <option value="Theft">Theft</option>
                    <option value="Unknown">Unknown</option>
                    <option value="Voluntary Workers">Voluntary Workers</option>
                    <option value="Water Damage">Water Damage</option>
                    <option value="WH&S Appeal Expenses">
                      WH&S Appeal Expenses
                    </option>
                  </b-form-select>
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <label class="col-2 col-form-label text-right">Date of Loss<span class="text-danger">*</span></label>
              <div class="col-2">
                <ValidationProvider rules="required" name="K_DateOfLoss" v-slot="{ errors }">
                  <b-form-datepicker class="form-control" :locale="'en-AU'" v-model="appData.claim.K_DateOfLoss"
                    placeholder="DD/MM/YYYY" :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }" :max="today" :disabled="isReadOnly || !isEditMode">
                  </b-form-datepicker>
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <label class="col-2 col-form-label text-right">Claim Owner<span class="text-danger">*</span></label>
              <div class="col-2">
                <ValidationProvider rules="required" name="Owner" v-slot="{ errors }">
                  <b-form-select class="form-control" v-model="appData.claim.Owner"
                    :disabled="isReadOnly || !isEditMode">
                    <option v-for="user in refData.activeUsers" :key="user.id" :value="user">
                      {{ user.name }}
                    </option>
                  </b-form-select>
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <!-- ROW 3 -->
            <div class="form-group row mb-3">
              <label class="col-2 col-form-label text-right">Claim Excess</label>
              <div class="col-2">
                <ValidationProvider rules="" name="K_ClaimExcess" v-slot="{ errors }">
                  <currency-input class="form-control" v-model="appData.claim.K_ClaimExcess" :allow-negative="false"
                    :distraction-free="false" :precision="0" :disabled="isReadOnly || !isEditMode" />
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <label class="col-2 col-form-label text-right">Strata Manager Claim Number</label>
              <div class="col-2">
                <ValidationProvider rules="max:255" name="K_StrataManagerClaimNumber" v-slot="{ errors }">
                  <input class="form-control" maxlength="255" v-model="appData.claim.K_StrataManagerClaimNumber"
                    :disabled="isReadOnly || !isEditMode" />
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <label class="col-2 col-form-label text-right">Claim Sub-Status</label>
              <div class="col-2">
                <b-form-select class="form-control" v-model="appData.claim.K_SubStatus"
                  :disabled="isReadOnly || !isEditMode">
                  <option :value="null">-- Select --</option>
                  <option value="Panel Builder Appointed">
                    Panel Builder Appointed
                  </option>
                  <option value="Loss Adjustor Appointed">
                    Loss Adjustor Appointed
                  </option>
                  <option value="Claim In Progress - Awaiting Excess/Contract">
                    Claim In Progress - Awaiting Excess/Contract
                  </option>
                  <option value="Claim In Progress - Awaiting Client Information">
                    Claim In Progress - Awaiting Client Information
                  </option>
                  <option value="Claim In Progress - Awaiting Supplier/Insurer Information">
                    Claim In Progress - Awaiting Supplier/Insurer Information
                  </option>
                  <option value="Claim In Progress - Awaiting Settlement/Total Claim Cost">
                    Claim In Progress - Awaiting Settlement/Total Claim Cost
                  </option>
                  <option value="Claim In Progress - Complaint">
                    Claim In Progress - Complaint
                  </option>
                  <option value="Repairs In Progress">
                    Repairs In Progress
                  </option>
                  <option value="Declined - IDR">Declined - IDR</option>
                  <option value="Declined - AFCA">Declined - AFCA</option>
                  <option value="Claim On Hold - Client Maintenance">
                    Claim On Hold - Client Maintenance
                  </option>
                  <option value="Claim On Hold - Internal Review">
                    Claim On Hold - Internal Review
                  </option>
                  <option value="Recovery In Progress">
                    Recovery In Progress
                  </option>
                  <option value="Claim Re-opened">Claim Re-opened</option>
                </b-form-select>
              </div>
            </div>

            <!-- ROW 4 -->
            <div class="form-group row mb-4">
              <label class="col-2 col-form-label text-right">Indemnity Status</label>
              <label class="col-2 col-form-label font-weight-bold">
                {{ appData.claim.K_IndemnityStatus }}
              </label>
              <!--              <label class="col-2 col-form-label text-right">Claim Created Date</label>-->
              <!--              <label class="col-2 col-form-label font-weight-bold">-->
              <!--                {{ appData.claim.Created_Time | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}-->
              <!--              </label>-->

              <label class="col-2 col-form-label text-right">Claim Age (Days)</label>
              <label class="col-2 col-form-label font-weight-bold">{{
                appData.claim.K_ClaimAge
                }}</label>

              <label class="col-2 col-form-label text-right">Insurer Claim Number</label>
              <label class="col-2 col-form-label font-weight-bold">{{
                appData.claim.K_InsurerClaimNumber
                }}</label>
            </div>

            <!-- ROW 5 -->
            <div class="form-group row">
              <label class="col-2 col-form-label text-right">Description of Loss/Damage<span
                  class="text-danger">*</span></label>
              <div class="col-10">
                <ValidationProvider rules="required|max:2000" name="K_DescriptionOfLossDamage" v-slot="{ errors }">
                  <textarea class="form-control min-rows" maxlength="2000"
                    v-model="appData.claim.K_DescriptionOfLossDamage" :disabled="isReadOnly || !isEditMode"
                    ref="textarea" @input="resizeTextarea">
                  </textarea>
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <!-- ROW 6 -->
            <div class="form-group row mb-4">
              <label class="col-2 col-form-label text-right">Circumstances of Loss</label>
              <div class="col-10">
                <ValidationProvider rules="max:2000" name="K_CircumstancesOfLoss" v-slot="{ errors }">
                  <textarea class="form-control min-rows" maxlength="2000" v-model="appData.claim.K_CircumstancesOfLoss"
                    :disabled="isReadOnly || !isEditMode" ref="textarea" @input="resizeTextarea">
                  </textarea>
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <!-- ROW 7 -->
            <div class="form-group row mb-4">
              <label class="col-2 col-form-label text-right">Description of Items Lost/Stolen/Damaged</label>
              <div class="col-10">
                <ValidationProvider rules="max:2000" name="K_DescriptionOfItemsLostStolenDamaged" v-slot="{ errors }">
                  <textarea class="form-control min-rows" maxlength="2000" v-model="
                      appData.claim.K_DescriptionOfItemsLostStolenDamaged
                    " :disabled="isReadOnly || !isEditMode" ref="textarea" @input="resizeTextarea">
                  </textarea>
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <!-- ROW 8 -->
            <div class="form-group row mb-4">
              <label class="col-2 col-form-label text-right">Cause of Damage</label>
              <div class="col-10">
                <ValidationProvider rules="max:2000" name="K_CauseOfDamage" v-slot="{ errors }">
                  <textarea class="form-control min-rows" maxlength="2000" v-model="appData.claim.K_CauseOfDamage
                        " :disabled="isReadOnly || !isEditMode" ref="textarea" @input="resizeTextarea">
                  </textarea>
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>

            <!-- EFT Details -->
            <b-card border-variant="secondary" body-class="px-2 py-2">
              <b-card-text><strong>EFT Details</strong></b-card-text>
              <div class="form-group row">
                <label class="col-2 col-form-label text-right">Account Name</label>
                <div class="col-3">
                  <ValidationProvider rules="max:255" name="K_BankAccountName" v-slot="{ errors }">
                    <input class="form-control" maxlength="255" v-model="appData.claim.K_BankAccountName"
                      :disabled="isReadOnly || !isEditMode" />
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <label class="col-1 col-form-label text-right">BSB</label>
                <div class="col-2">
                  <ValidationProvider rules="max:7" name="K_Bsb" v-slot="{ errors }">
                    <input class="form-control" maxlength="7" v-model="appData.claim.K_Bsb"
                      :disabled="isReadOnly || !isEditMode" />
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <label class="col-2 col-form-label text-right">Account Number</label>
                <div class="col-2">
                  <ValidationProvider rules="max:255" name="K_BankAccountNumber" v-slot="{ errors }">
                    <input class="form-control" maxlength="255" v-model="appData.claim.K_BankAccountNumber"
                      :disabled="isReadOnly || !isEditMode" />
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </b-card>

            <div class="mt-4"></div>

            <!-- GST Details -->
            <b-card border-variant="secondary" body-class="px-2 py-2">
              <b-card-text><strong>GST Details</strong></b-card-text>
              <div class="form-group row">
                <label class="col-2 col-form-label text-right">ABN</label>
                <div class="col-3">
                  <ValidationProvider rules="max:255" name="K_Abn" v-slot="{ errors }">
                    <input class="form-control" maxlength="255" :value="maskedAbn" @input="handleInput"
                      @blur="handleBlur" @focus="handleFocus" :disabled="isReadOnly || !isEditMode" />
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <label class="col-1 col-form-label text-right">ITC</label>
                <div class="col-2">
                  <ValidationProvider rules="max:3" name="K_ItcPercent" v-slot="{ errors }">
                    <input class="form-control" maxlength="7" v-model="appData.claim.K_ItcPercent"
                      :disabled="isReadOnly || !isEditMode" />
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
                <label class="col-2 col-form-label text-right">GST Registered</label>
                <div class="col-2">
                  <ValidationProvider rules="max:3" name="K_GstRegistered" v-slot="{ errors }">
                    <b-form-select class="form-control" v-model="appData.claim.K_GstRegistered"
                      :disabled="isReadOnly || !isEditMode">
                      <option :value="null">-- Select --</option>
                      <option value="Yes">Yes</option>
                      <option value="NoR">No</option>
                    </b-form-select>
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
            </b-card>
          </ValidationObserver>

          <div class="form-group mt-5">
            <button style="
                display: flex;
                align-items: center;
                justify-content: center;
              " class="btn btn-primary col-2" v-if="!isReadOnly && !isEditMode" @click.prevent="editData"
              :disabled="savingData">
              <i class="fas fa-edit"></i> Edit Claim Summary
            </button>
            <button class="btn btn-primary col-2" v-if="!isReadOnly && isEditMode" @click.prevent="saveData"
              :disabled="savingData">
              <i class="fas fa-save pr-2"></i>Save Claim Summary
            </button>
            <b-spinner class="ml-3 align-middle" variant="primary" v-if="savingData"></b-spinner>
            <button class="btn btn-secondary ml-3" v-if="!isReadOnly && isEditMode" @click.prevent="cancelEditMode"
              :disabled="savingData">
              Cancel
            </button>
          </div>
        </div>
      </b-collapse>
    </b-card>
  </div>
</template>

<style>
.form-control.min-rows {
  min-height: calc(1.5em * 3 + 2px);
}
textarea {
  overflow: hidden;
}
</style>

<script>
import _ from "lodash";
export default {
  name: "ClaimSummary",

  props: {
    appData: Object,
    refData: Object,
    isReadOnly: Boolean,
  },

  data() {
    return {
      isExpanded: true,
      isEditMode: false,
      originalData: {},
      savingData: false,
      maskedAbn: '',
      textareaRefs: [],
    };
  },

  updated() {
    this.resizeTextarea();
  },

  methods: {
    editData: function () {
      this.originalData = this.getClaimSummaryData();
      this.isEditMode = true;
    },

    resizeTextarea() {
      this.$nextTick(() => {
        const textareas = document.querySelectorAll('textarea');
        textareas.forEach((textarea) => {
          textarea.style.height = 'auto';
          textarea.style.height = (textarea.scrollHeight + 5) + 'px';
        });
      });
    },

    cancelEditMode: function () {
      for (let key of Object.keys(this.originalData)) {
        this.$set(this.appData.claim, key, this.originalData[key]);
      }
      this.originalData = {};
      this.isEditMode = false;
    },

    saveData: function () {
      this.$refs.claimSummaryData.validate().then((isValid) => {
        if (isValid) {
          this.savingData = true;
          let updatedData = this.getClaimSummaryData();
          this.$claimDataService
            .updateClaimData(updatedData)
            .then(({ data }) => {
              let result = _.get(data, "result");

              if (result && result.hasOwnProperty("Name")) {
                this.$set(this.appData.claim, "Name", result.Name);
              }
              if (result && result.hasOwnProperty("K_Status")) {
                this.$set(this.appData, "statusFlags", result.statusFlags);
                this.$set(this.appData.claim, "K_Status", result.K_Status);
                this.$set(this.appData.claim, "K_NextStep", result.K_NextStep);
              }
              this.$emit("updateGlobalClaimStatus");
              this.originalData = {};
              this.isEditMode = false;
              this.savingData = false;
            })
            .catch((error) => {
              this.savingData = false;
              this.$emit("handleError", error);
            });
        }
      });
    },

    getClaimSummaryData: function () {
      return {
        // Name: this.appData.claim.Name,
        Owner:            this.appData.claim.Owner,
        K_TypeOfClaim:    this.appData.claim.K_TypeOfClaim,
        K_DateOfLoss:     this.appData.claim.K_DateOfLoss,
        K_ClaimExcess:    this.appData.claim.K_ClaimExcess,
        K_SubStatus:      this.appData.claim.K_SubStatus,
        K_StrataManagerClaimNumber:
                          this.appData.claim.K_StrataManagerClaimNumber,
        K_DescriptionOfLossDamage: this.appData.claim.K_DescriptionOfLossDamage,
        K_CauseOfDamage:    this.appData.claim.K_CauseOfDamage,
        K_CircumstancesOfLoss: this.appData.claim.K_CircumstancesOfLoss,
        K_DescriptionOfItemsLostStolenDamaged:
                          this.appData.claim.K_DescriptionOfItemsLostStolenDamaged,
        K_BankAccountName: this.appData.claim.K_BankAccountName,
        K_BankAccountNumber: this.appData.claim.K_BankAccountNumber,
        K_Bsb:            this.appData.claim.K_Bsb,
        K_Abn:            this.appData.claim.K_Abn,
        K_ItcPercent:     this.appData.claim.K_ItcPercent,
        K_GstRegistered:  this.appData.claim.K_GstRegistered,
      };
    },

    formatAbn(abn) {
      return abn.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4');
    },
    handleInput(event) {
      const rawValue = event.target.value.replace(/\s+/g, '');
      this.appData.claim.K_Abn = rawValue;
      this.maskedAbn = this.formatAbn(rawValue);
    },
    handleFocus() {
      if (this.appData.claim && this.appData.claim.K_Abn !== undefined) {
        this.maskedAbn = this.appData.claim.K_Abn.replace(/\s+/g, '');
      }
    },
    handleBlur() {
      if (this.appData.claim && this.appData.claim.K_Abn !== undefined) {
        this.maskedAbn = this.formatAbn(this.appData.claim.K_Abn);
      }
    }
  },

  mounted() {
    if (this.appData.claim.K_Abn !== null && this.appData.claim.K_Abn !== undefined) {
      this.maskedAbn = this.formatAbn(this.appData.claim.K_Abn);
    }

    this.$nextTick(() => {
      this.resizeTextarea();
    });
  },

  computed: {
    today: function () {
      return new Date();
    },
  },
};
</script>
