<template>
  <div>
    <b-card border-variant="primary" body-class="px-4 py-4" class="mb-10">
      <h4 class="font-weight-bold text-dark">
        <i class="fas fa-exclamation-circle text-danger fa-md"></i>
        <span class="ml-2" style="vertical-align: center">Claim Alert</span>
      </h4>

      <div class="form-group row">
        <label class="col-1 col-form-label">Alert</label>
        <div class="col-7">
          <ValidationProvider rules="max:255" name="K_TypeOfClaim" v-slot="{ errors }">
            <input type="text" class="form-control" maxlength="255" v-model="dataBeingEdited.K_ClaimAlert"
              :disabled="isReadOnly || !isEditMode" />
            <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
          </ValidationProvider>
        </div>

        <label class="col-2 col-form-label text-right">Complaint Status</label>
        <div class="col-2">
          <b-form-select class="form-control" v-model="dataBeingEdited.K_ComplaintStatus"
            :disabled="isReadOnly || !isEditMode">
            <option :value="null">-- Select --</option>
            <option value="Service Complaint">Service Complaint</option>
            <option value="Coverage Complaint">Coverage Complaint</option>
            <option value="CIB Complaint">CIB Complaint</option>
            <option value="IDR">IDR</option>
            <option value="AFCA">AFCA</option>
            <option value="Contentious">Contentious</option>
            <option value="Management">Management</option>
          </b-form-select>
        </div>
      </div>

      <div class="form-group mt-2 mb-0" v-if="!isEditMode && !isReadOnly">
        <button class="btn btn-primary" @click.prevent="editData">
          <i class="fa fa-edit"></i> Edit
        </button>
      </div>
      <div class="form-group mt-2" v-if="isEditMode">
        <button class="btn btn-primary" @click="saveData" :disabled="savingData">
          Save
        </button>
        <b-spinner class="ml-3 align-middle" variant="primary" v-if="savingData"></b-spinner>
        <button class="btn btn-secondary ml-3" @click="cancelEditMode" :disabled="savingData">
          Cancel
        </button>
      </div>
    </b-card>
  </div>
</template>

<style lang="scss"></style>

<script>
export default {
  name: "ClaimAlert",
  props: {
    appData: Object,
    isReadOnly: Boolean,
  },

  data() {
    return {
      dataBeingEdited: {},
      isEditMode: false,
      savingData: false,
    };
  },

  mounted() {
    this.populateDataBeingEdited();
  },

  methods: {
    populateDataBeingEdited: function () {
      this.$set(
        this.dataBeingEdited,
        "K_ClaimAlert",
        this.appData.claim.K_ClaimAlert
      );
      this.$set(
        this.dataBeingEdited,
        "K_ComplaintStatus",
        this.appData.claim.K_ComplaintStatus
      );
    },

    editData: function () {
      this.populateDataBeingEdited();
      this.isEditMode = true;
    },

    cancelEditMode: function () {
      this.populateDataBeingEdited();
      this.isEditMode = false;
    },

    saveData: function () {
      this.savingData = true;
      this.$claimDataService
        .updateClaimData(this.dataBeingEdited)
        .then((/*{data}*/) => {
          for (let key of Object.keys(this.dataBeingEdited)) {
            this.appData.claim[key] = this.dataBeingEdited[key];
          }
          this.populateDataBeingEdited();

          this.isEditMode = false;
          this.savingData = false;
        })
        .catch((error) => {
          this.$emit("handleError", error);
        });
    },
  },
};
</script>
