<template>
    <ValidationObserver ref="cancellationWizard">
        <div class="card card-custom">
            <div class="card-body p-0">
                <!--begin: Wizard-->
                <div class="wizard wizard-1" id="cancellation_wizard" data-wizard-state="step-first"
                    data-wizard-clickable="true">
                    <!--begin: Wizard Nav -->
                    <div class="wizard-nav border-bottom mb-1 mb-lg-5">
                        <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
                            <div v-for="(wizardPage, index) in wizardPages" :key="index" class="wizard-step"
                                style="cursor: default" data-wizard-type="step">
                                <div class="wizard-label">
                                    <h3 class="wizard-title">
                                        <span>{{ index + 1 }}</span>{{ wizardPage.title }}
                                    </h3>
                                    <div class="wizard-bar"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end: Wizard Nav -->
                    <!--begin: Wizard Body-->
                    <div class="card card-custom card-shadowless rounded-top-0">
                        <div class="card-body p-0">
                            <div class="row justify-content-center py-8 px-8 py-lg-10 px-lg-10">
                                <div class="col-xl-12 col-xxl-10">
                                    <!--begin: Wizard Form-->
                                    <div class="form" id="newbusiness_wizard_form">
                                        <!-- ERROR ALERTS -->
                                        <b-alert v-if="errorAlert" variant="warning" show>
                                            <div class="text-dark" v-html="errorAlert"></div>
                                        </b-alert>
                                        <!--begin: Wizard Page 1-->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData && referenceData">
                                                <new-policy-page :app-data="wizardAppData" :ref-data="referenceData"
                                                    :is-read-only="isWizardReadOnly || acceptedQuoteExists
                                                        " @updateWizardTitle="setHeaderInformation"
                                                    @updateServerTransactionStatus="updateServerTransactionStatus
                                                        " @handleError="handleError" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 1-->

                                        <!--begin: Wizard Page 2-->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData && referenceData">
                                                <declaration-page :app-data="wizardAppData" :ref-data="referenceData"
                                                    :is-read-only="isWizardReadOnly || acceptedQuoteExists
                                                        " @updateServerTransactionStatus="updateServerTransactionStatus
        " @updateWizardAppDataString="updateWizardAppDataString" @handleError="handleError" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 2-->

                                        <!--begin: Wizard Page 3-->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData">
                                                <review-page :app-data="wizardAppData" :is-read-only="isWizardReadOnly || acceptedQuoteExists
                                                    " @overrideDeclaration="overrideDeclarationForm"
                                                    @updateServerTransactionStatus="updateServerTransactionStatus
                                                        " @updateWizardAppDataString="updateWizardAppDataString" @handleError="handleError" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 3-->

                                        <!--begin: Wizard Page 4-->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData && referenceData">
                                                <insurers-page :app-data="wizardAppData" :ref-data="referenceData"
                                                    :is-read-only="isWizardReadOnly || acceptedQuoteExists
                                                        " @updateServerTransactionStatus="updateServerTransactionStatus
        " @updateWizardAppDataString="updateWizardAppDataString" @handleError="handleError" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 4-->
                                        <!--begin: Wizard Page 5-->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData">
                                                <quotes-page :app-data="wizardAppData" :manager-users="managerUsers"
                                                    :is-current-user-manager="isCurrentUserManager" :is-read-only="isWizardReadOnly || acceptedQuoteExists
                                                        " @saveQuote="saveQuoteToServer"
                                                    @setRecommendedQuote="setRecommendedQuote"
                                                    @applyBrokerFeeToAllQuotes="applyBrokerFeeToAllQuotes"
                                                    @createQuoteComparisonTable="createQuoteComparisonTable
                                                        " @statusUpdated="updateUiTransactionStatus"
                                                    @requestBrokerAdviceReview="requestBrokerAdviceReview"
                                                    @approveBrokerAdvice="approveOrRejectBrokerAdvice"
                                                    @rejectBrokerAdvice="approveOrRejectBrokerAdvice"
                                                    @updateServerTransactionStatus="updateServerTransactionStatus
                                                        " @updateWizardAppDataString="updateWizardAppDataString"
                                                    @handleError="handleError" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 5-->
                                        <!--begin: Wizard Page 6-->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData">
                                                <finalisation-page :app-data="wizardAppData"
                                                    :is-read-only="isWizardReadOnly" @acceptQuote="acceptQuote"
                                                    @statusUpdated="updateUiTransactionStatus"
                                                    @updateServerTransactionStatus="updateServerTransactionStatus
                                                        " @updateWizardAppDataString="updateWizardAppDataString"
                                                    @handleError="handleError" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 6-->
                                        <!--begin: Wizard Page 7-->
                                        <div class="pb-5" data-wizard-type="step-content">
                                            <div v-if="wizardAppData">
                                                <invoice-page :app-data="wizardAppData"
                                                    @statusUpdated="updateUiTransactionStatus"
                                                    :is-read-only="isWizardReadOnly" />
                                            </div>
                                        </div>
                                        <!--end: Wizard Page 7-->

                                        <!--begin: Wizard Actions -->
                                        <div class="d-flex justify-content-between border-top pt-10">
                                            <button class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                                                v-show="wizardAppData && !isFirstPage" @click.prevent="goToPreviousPage">
                                                Previous Step
                                            </button>
                                            <button class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                                                v-show="wizardAppData && !isLastPage && !showFinaliseButton
                                                    " @click.prevent="goToNextPage">
                                                Next Step
                                            </button>
                                            <button class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                                                v-show="showFinaliseButton" @click.prevent="finaliseTransaction">
                                                Finalise Transaction
                                            </button>
                                        </div>
                                        <!--end: Wizard Actions -->
                                    </div>
                                    <!--end: Wizard Form-->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end: Wizard Body-->
                </div>
                <!--end: Wizard-->
            </div>
        </div>
    </ValidationObserver>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-3.scss";

.switch-radio .v-input--selection-controls__ripple {
    margin: 2px 0;
    height: 24px;
    width: 40px;
    border-radius: 12px;
    position: absolute;
    display: inline-block;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.switch-radio .v-input--selection-controls__ripple:before {
    border: 2px solid #95979d;
    background-color: transparent;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: unset;
}

.switch-radio .v-input--selection-controls__ripple:after {
    content: "\f108";
    height: 20px;
    width: 20px;
    top: 2px;
    left: 0px;
    bottom: 2px;
    margin-left: 2px;
    font-size: 0.55em;
    text-align: center;
    vertical-align: middle;
    position: absolute;
    border-radius: 50%;
    background: #ebedf3;
    transition: all 0.5s;
    font-family: ki;
    color: #ebedf3;
    line-height: 2;
}

.switch-radio input:empty~span:before {
    border: 2px solid #ebedf3;
    background-color: transparent;
    transform: unset;
}

.switch-radio input:checked~.v-input--selection-controls__ripple:after {
    left: 16px;
    background: #3699ff;
}

.switch-radio .v-input--selection-controls__input {
    width: 50px;
}

.switch-radio .v-input--selection-controls__input i {
    display: none;
}

.switch-radio input:checked~.v-input--selection-controls__ripple:before {
    border-color: #3699ff;
    opacity: 1;
    background-color: transparent;
}

.switch-radio .v-input--selection-controls__input:hover .v-input--selection-controls__ripple:before {
    background-color: transparent;
    transform: unset;
}

.switch-radio .v-ripple__container {
    display: none;
}

.switch-radio label {
    margin-bottom: 0;
}
</style>

<script>
import _ from "lodash";
import stringify from "fast-json-stable-stringify";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import {
    ADD_BODY_CLASSNAME,
    REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

// import CancellationPage from "./pages/1-Cancellation/CancellationPage.vue";
import Swal from "sweetalert2";
import NewPolicyPage from "@/view/pages/wizard/new-business/pages/1-NewPolicy/NewPolicyPage.vue";
import DeclarationPage from "@/view/pages/wizard/renewal/pages/2-Declaration/DeclarationPage.vue";
import ReviewPage from "@/view/pages/wizard/claim/pages/1-Review/ReviewPage.vue";
import InsurersPage from "@/view/pages/wizard/renewal/pages/4-Insurers/InsurersPage.vue";
import QuotesPage from "@/view/pages/wizard/renewal/pages/5-Quotes/QuotesPage.vue";
import FinalisationPage from "@/view/pages/wizard/claim/pages/4-Finalisation/FinalisationPage.vue";
import InvoicePage from "@/view/pages/wizard/renewal/pages/7-Invoice/InvoicePage.vue";

export default {
    name: "CancellationWizard",
    components: {
        InvoicePage,
        FinalisationPage,
        QuotesPage,
        InsurersPage,
        ReviewPage,
        DeclarationPage,
        NewPolicyPage
        // CancellationPage,
    },
    data() {
        return {
            wizardPages: [
                {
                    id: "wizardPage1",
                    title: "Cancellation",
                    subtitle: "Cancellation",
                },
            ],

            userId: null,
            wizardObj: null,
            errorAlert: null,
            wizardAppData: null,
            referenceData: null,
            appDataString: null,
        };
    },

    mounted() {
        // Show page loading
        this.startPageLoading();

        // Extract information from URL params
        let queryParams = this.$route.query;
        if (
            !queryParams.userId ||
            !queryParams.transactionId ||
            !queryParams.token
        ) {
            this.handleError("Unauthorised access!");
            return;
        }

        this.userId = queryParams.userId;

        // Initialise EndorsementDataService with the token
        this.$newBusinessDataService.init(
            queryParams.transactionId,
            queryParams.userId,
            queryParams.token
        );

        // Initialise CommonDataService with the token
        this.$commonDataService.init(
            queryParams.transactionId,
            queryParams.userId,
            queryParams.token
        );

        // Initialise the UI wizard
        this.initWizard();

        // Get Reference Data
        this.getReferenceData();

        // Get Application Data
        this.getWizardAppData();
    },

    computed: {
        isWizardReadOnly: function () {
            let txnStatus = _.get(this.wizardAppData, "transaction.K_Status");
            return txnStatus === "Completed";
        },

        managerUsers: function () {
            let managerUsers = [];
            if (Array.isArray(this.users)) {
                // Remove current user from manager-users (shouldn't be able to ask for own approval)
                managerUsers = _.filter(this.users, (user) => {
                    return user.id !== this.userId && user.K_ManagerReviewer === true;
                });
            }
            return managerUsers;
        },

        isCurrentUserManager: function () {
            if (Array.isArray(this.users)) {
                let currentUser = _.find(this.users, { id: this.userId });
                if (currentUser) {
                    return !!currentUser.K_ManagerReviewer;
                }
            }
            return false;
        },

        acceptedQuoteExists: function () {
            let acceptedQuoteId = _.get(this.wizardAppData, "acceptedQuote.id");
            return !!acceptedQuoteId;
        },

        isLastPage: function () {
            if (this.wizardObj && this.wizardAppData) {
                let txnType = this.wizardAppData.transaction.K_Type;
                if (txnType === "Take-Up") {
                    return this.wizardObj.getStep() === 3;
                } else {
                    return this.wizardObj.isLastStep();
                }
            }
            return false;
        },

        isFirstPage: function () {
            return this.wizardObj && this.wizardObj.isFirstStep();
        },

        showFinaliseButton: function () {
            return (
                this.wizardAppData &&
                !this.isWizardReadOnly &&
                ((this.wizardAppData.transaction.K_Type === "New Business" &&
                    this.isLastPage) ||
                    (this.wizardAppData.transaction.K_Type === "Take-Up" &&
                        this.wizardObj.getStep() === 3))
            );
        },
    },

    methods: {
        initWizard: function () {
            // Initialize form wizard
            const wizard = new KTWizard("newbusiness_wizard", {
                startStep: 1, // Initial active step number
                clickableSteps: false, // Do not allow step clicking
            });

            this.wizardObj = wizard;

            // Before-Change event
            wizard.on("change", (/*wizardObj*/) => {
                this.clearError();
                if (this.wizardAppData) {
                    // Save app-data to server
                    this.saveWizardAppData();
                }
            });

            // Page-Changed event - Scroll to top of the page
            wizard.on("changed", (wizardObj) => {
                // Save current page number to server
                let currentStep = wizardObj.getStep();
                this.saveWizardCurrentPage(currentStep);

                setTimeout(function () {
                    KTUtil.scrollTop();
                }, 500);
            });
        },

        getReferenceData: function () {
            this.$newBusinessDataService
                .getReferenceData()
                .then(({ data }) => {
                    this.referenceData = data.result;
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        isPageValid: async function (pageNumber) {
            let isValid = false;
            if (!this.$refs || !this.$refs.newbusinessWizard || !this.wizardAppData) {
                return isValid;
            }

            let page = this.wizardPages[pageNumber - 1];
            if (!page) {
                console.log(`Invalid page number ${pageNumber}`);
                return isValid;
            }

            let wizardPageId = page.id;
            let isDevelopment = process.env.NODE_ENV === "development";

            let childrenPages = this.$refs.newbusinessWizard.$children;
            if (Array.isArray(childrenPages)) {
                let currentPageComponent = _.find(childrenPages, (child) => {
                    return child.$el && child.$el.id === wizardPageId;
                });
                if (!currentPageComponent) {
                    if (isDevelopment) {
                        console.log(
                            `Could not find currentPageComponent for id ${wizardPageId}`
                        );
                    }
                } else {
                    let currentPageRef = currentPageComponent.$refs[wizardPageId];
                    if (!currentPageRef) {
                        if (isDevelopment) {
                            console.log(
                                `Could not find currentPageRef for id ${wizardPageId}`
                            );
                        }
                    } else {
                        isValid = await currentPageRef.validate();
                        if (!isValid && isDevelopment) {
                            console.log(currentPageRef.errors);
                        }
                    }
                }
            }
            return isValid;
        },

        goToNextPage: async function () {
            this.clearError();
            let isNavAllowed = false;

            if (this.isWizardReadOnly) {
                isNavAllowed = true;
            } else {
                // Validate the current page
                isNavAllowed = await this.isPageValid(this.wizardObj.getStep());
            }
            if (isNavAllowed) {
                // Save app-data to server
                this.saveWizardAppData();

                if (
                    this.wizardObj.getStep() === 1 &&
                    this.wizardAppData.declarationRequired === "no"
                ) {
                    // Skip page 2 and go to Review page
                    this.wizardObj.goTo(3);
                } else {
                    // Go to next page
                    this.wizardObj.goNext();
                }
            }
        },

        goToPreviousPage: async function () {
            this.clearError();

            // Save app-data to server
            this.saveWizardAppData();

            if (
                this.wizardObj.getStep() === 3 &&
                this.wizardAppData.declarationRequired === "no"
            ) {
                // Go to first page
                this.wizardObj.goTo(1);
            } else {
                // Go to previous page
                this.wizardObj.goPrev();
            }
        },

        getWizardAppData: function () {
            this.$newBusinessDataService
                .getWizardAppData()
                .then(({ data }) => {
                    if (!data.result) {
                        throw new Error('Failed to get wizard application data');
                    }

                    this.wizardAppData = data.result;
                    this.appDataString = stringify(this.wizardAppData);

                    // Set wizard title and subtitle
                    this.setHeaderInformation();

                    // Set starting step for the wizard
                    let startStep = 1;
                    let txnType = this.wizardAppData.transaction.K_Type;
                    if (this.isWizardReadOnly) {
                        // If transaction is already completed, directly start the wizard on last page.
                        if (txnType === "Take-Up") {
                            startStep = 3;
                        } else {
                            startStep = this.wizardPages.length;
                        }
                    } else {
                        // Check if the user was previously on a particular wizard page
                        startStep = this.wizardAppData.transaction.K_WizardCurrentPage || 1;
                    }

                    // Stop page loading
                    this.stopPageLoading();

                    if (startStep !== 1) {
                        setTimeout(() => {
                            this.wizardObj.goTo(startStep);
                        }, 0);
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        saveWizardAppData: function (noSpinner) {
            if (this.isWizardReadOnly) {
                return;
            }
            // Save app-data to the server (only if it has changed)
            let currentAppDataString = stringify(this.wizardAppData);
            if (currentAppDataString !== this.appDataString) {
                if (!noSpinner) {
                    this.startPageLoading();
                }

                this.$newBusinessDataService
                    .saveWizardAppData(this.wizardAppData)
                    .then(({ data }) => {
                        this.wizardAppData = data.result;
                        this.appDataString = stringify(this.wizardAppData);

                        // Update transaction status on the header
                        this.updateUiTransactionStatus();

                        if (!noSpinner) {
                            this.stopPageLoading();
                        }
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            }
        },

        saveWizardCurrentPage: function (pageNumber) {
            if (this.isWizardReadOnly) {
                return;
            }

            this.$commonDataService
                .saveWizardCurrentPage(pageNumber)
                .catch((error) => {
                    this.handleError(error);
                });
        },

        saveQuoteToServer: function (quote) {
            if (this.isWizardReadOnly) {
                return;
            }
            this.clearError();

            this.startPageLoading();
            // Save quote
            this.$newBusinessDataService
                .saveQuoteDetails(quote)
                .then((/*{ data }*/) => {
                    // Refresh wizard-app-data
                    this.refreshWizardAppData();

                    // Reset quote comparison data (because it would have changed when quote was updated)
                    this.wizardAppData.quoteComparisonData = null;
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        applyBrokerFeeToAllQuotes: function () {
            if (this.isWizardReadOnly) {
                return;
            }
            this.clearError();

            let feesAndCommissions = _.pick(this.wizardAppData.transaction, [
                "K_BrokerFee",
                "K_BrokerFeeGst",
            ]);
            this.startPageLoading();

            // Apply fees to all quotes
            this.$newBusinessDataService
                .applyBrokerFeeToAllQuotes(feesAndCommissions)
                .then(({ data }) => {
                    let updatedStatus = data.result;
                    if (updatedStatus) {
                        // Update appData flags
                        this.wizardAppData.statusFlags[updatedStatus.K_StatusFlag] = true;

                        // Update UI transaction status
                        let txn = this.wizardAppData.transaction;
                        txn.K_Status = updatedStatus.K_Status;
                        txn.K_StatusDetails = updatedStatus.K_StatusDetails;
                        txn.K_NextStep = updatedStatus.K_NextStep;
                        this.updateUiTransactionStatus();
                    }

                    this.$newBusinessDataService
                        .refreshQuotes()
                        .then(({ data }) => {
                            this.wizardAppData.quotes = data.result;
                            this.stopPageLoading();

                            this.updateWizardAppDataString();
                        })
                        .catch((error) => {
                            this.handleError(error);
                        });
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        setRecommendedQuote: function (quote) {
            if (this.isWizardReadOnly) {
                return;
            }
            this.clearError();

            this.$set(quote, "saving", true);
            let quoteId = quote.id;
            this.$newBusinessDataService
                .setRecommendedQuote(quote.id)
                .then(() => {
                    this.$newBusinessDataService
                        .refreshQuotes()
                        .then(({ data }) => {
                            this.wizardAppData.quotes = data.result;
                            let updatedQuote = _.find(this.wizardAppData.quotes, {
                                id: quoteId,
                            });
                            if (updatedQuote) {
                                this.$set(updatedQuote, "saving", false);
                                this.$set(updatedQuote, "K_RecommendedQuote", true);
                            }

                            this.updateWizardAppDataString();
                        })
                        .catch((error) => {
                            this.$set(quote, "saving", false);
                            this.handleError(error);
                        });
                })
                .catch((error) => {
                    this.$set(quote, "saving", false);
                    this.handleError(error);
                });
        },

        createQuoteComparisonTable: function () {
            this.clearError();
            if (this.isWizardReadOnly) {
                return;
            }

            // Check if a recommended-quote exists
            let recommendedQuote = _.find(this.wizardAppData.quotes, {
                K_RecommendedQuote: true,
            });
            if (!recommendedQuote || !recommendedQuote.id) {
                this.setError("Please set one of the quotes as the recommended quote.");
                return;
            }
            this.clearError();
            this.startPageLoading();
            this.$newBusinessDataService
                .getQuoteComparisonData()
                .then(({ data }) => {
                    this.wizardAppData.quoteComparisonData = data.result;

                    this.stopPageLoading();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        requestBrokerAdviceReview: function (brokerAdviceReviewData) {
            if (this.isWizardReadOnly) {
                return;
            }
            this.clearError();
            this.startPageLoading();
            this.$newBusinessDataService
                .requestBrokerAdviceReview(
                    brokerAdviceReviewData.reviewer,
                    brokerAdviceReviewData.comments
                )
                .then((/*{ data }*/) => {
                    // Refresh wizard-app-data
                    this.refreshWizardAppData();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        approveOrRejectBrokerAdvice: function (brokerAdviceManagerResponseData) {
            if (this.isWizardReadOnly) {
                return;
            }
            this.clearError();
            this.startPageLoading();
            this.$newBusinessDataService
                .approveOrRejectBrokerAdvice(
                    brokerAdviceManagerResponseData.status,
                    brokerAdviceManagerResponseData.comments
                )
                .then((/*{ data }*/) => {
                    // Refresh wizard-app-data
                    this.refreshWizardAppData();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        acceptQuote: function (quote) {
            if (this.isWizardReadOnly) {
                return;
            }
            this.clearError();
            this.startPageLoading();
            this.$newBusinessDataService
                .acceptQuote(quote.id)
                .then((/*{ data }*/) => {
                    // Refresh wizard-app-data
                    this.refreshWizardAppData();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        setHeaderInformation: function () {
            if (this.wizardAppData) {
                // Set wizard title and sub-title in global store so that it can be shown on the header
                if (this.wizardAppData.policy) {
                    let policyName = this.wizardAppData.policy.Name;

                    let txnType = this.wizardAppData.transaction.K_Type;
                    let title = `${txnType} - ${policyName}`;
                    this.$store.commit("setWizardTitle", title);

                    // if (this.wizardAppData.policy.K_Client) {
                    //   this.$store.commit(
                    //       "setWizardSubtitle",
                    //       this.wizardAppData.policy.K_Client.name
                    //   );
                    // }
                }

                // Update transaction status on the header
                this.updateUiTransactionStatus();

                // Update Box link on the sticky-toolbar
                this.setTransactionBoxLink();
            }
        },

        updateWizardAppDataString: function () {
            this.wizardAppDataString = stringify(this.wizardAppData);
        },

        updateServerTransactionStatus: function (flagName) {
            let flagValue = this.wizardAppData.statusFlags[flagName];
            if (_.isNil(flagValue)) {
                flagValue = true;
            }
            this.$newBusinessDataService
                .updateTransactionStatus(flagName, flagValue)
                .then(({ data }) => {
                    let updatedStatus = data.result;
                    if (updatedStatus) {
                        // Update UI transaction status
                        let txn = this.wizardAppData.transaction;
                        txn.K_Status = updatedStatus.K_Status;
                        txn.K_StatusDetails = updatedStatus.K_StatusDetails;
                        txn.K_NextStep = updatedStatus.K_NextStep;
                        this.updateUiTransactionStatus();

                        // Update wizardAppDataString
                        this.wizardAppDataString = stringify(this.wizardAppData);
                    }
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        updateUiTransactionStatus: function () {
            if (this.wizardAppData && this.wizardAppData.transaction) {
                let txn = this.wizardAppData.transaction;
                let txnStatus = {
                    status: txn.K_Status,
                    statusDetails: txn.K_StatusDetails,
                    nextStep: txn.K_NextStep,
                };
                // Set transaction status in global store so that it can be shown on the header
                this.$store.commit("setTransactionStatus", txnStatus);
            }
        },

        refreshWizardAppData: function () {
            this.$newBusinessDataService
                .getWizardAppData()
                .then(({ data }) => {
                    this.wizardAppData = data.result;
                    this.wizardAppDataString = stringify(this.wizardAppData);

                    // Update transaction status on the header
                    this.updateUiTransactionStatus();

                    this.stopPageLoading();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        setTransactionBoxLink: function () {
            let transactionBoxFolderId = _.get(
                this.wizardAppData,
                "transaction.K_TransactionBoxFolderId"
            );
            if (transactionBoxFolderId) {
                let txnBoxFolderLink = `https://collectiveib.app.box.com/folder/${transactionBoxFolderId}`;

                // Set transaction status in global store so that it can be shown on the header
                this.$store.commit("setTransactionBoxFolderLink", txnBoxFolderLink);
            }
        },

        overrideDeclarationForm: function (overrideReason) {
            if (this.isWizardReadOnly) {
                return;
            }
            this.clearError();

            this.startPageLoading();
            this.$newBusinessDataService
                .overrideDeclarationForm(overrideReason)
                .then((/*{ data }*/) => {
                    // Refresh wizard-app-data
                    this.refreshWizardAppData();
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        finaliseTransaction: function () {
            if (this.isWizardReadOnly) {
                return;
            }
            this.clearError();
            this.startPageLoading();

            // Save wizard-app-data to server
            this.$newBusinessDataService
                .saveWizardAppData(this.wizardAppData)
                .then((data) => {
                    this.wizardAppData = data.data.result;
                    this.wizardAppDataString = stringify(this.wizardAppData);

                    // Update transaction status on the header
                    this.updateUiTransactionStatus();

                    let txnType = this.wizardAppData.transaction.K_Type;
                    this.$newBusinessDataService
                        .finaliseTransaction()
                        .then((/*{ data }*/) => {
                            this.stopPageLoading();

                            this.$nextTick(() => {
                                Swal.fire({
                                    title: `${txnType} Finalised`,
                                    html: `This ${txnType} transaction has been finalised.<br/>
                               <span class="font-weight-bold">Please close this browser window.</span>`,
                                    icon: "success",
                                    allowOutsideClick: false,
                                    allowEnterKey: false,
                                    allowEscapeKey: false,
                                    showCancelButton: false,
                                    showCloseButton: false,
                                });
                            });
                        })
                        .catch((error) => {
                            this.handleError(error);
                        });
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },

        setError: function (message) {
            KTUtil.scrollTop();
            this.errorAlert = message;
        },

        clearError: function () {
            this.errorAlert = null;
        },

        handleError: function (errorObj) {
            console.log(errorObj);
            this.stopPageLoading();
            let errorMsg = null;

            if (errorObj) {
                let responseStatus = _.get(errorObj, "response.status");
                if (
                    responseStatus === 401 ||
                    (errorObj.message && errorObj.message.includes("status code 401"))
                ) {
                    errorMsg =
                        "This session is invalid or expired. Please close this window.";
                } else {
                    let responseResult = _.get(errorObj, "response.data.result");
                    if (_.isString(responseResult)) {
                        let applicationErrorPrefix = "UI_ERROR:";

                        if (responseResult.startsWith(applicationErrorPrefix)) {
                            errorMsg = responseResult.substring(
                                applicationErrorPrefix.length,
                                responseResult.length
                            );
                        }
                    }
                }
            }
            if (!errorMsg) {
                errorMsg = "An unexpected error has occurred.";
            }
            this.setError(errorMsg);
            KTUtil.scrollTop();
        },

        startPageLoading: function () {
            this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        },

        stopPageLoading: function () {
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
        },
    },
};
</script>
