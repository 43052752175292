<template>
  <ValidationObserver :ref="`claimContacts`">
    <div>
      <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
        <h4 class="font-weight-bold text-dark" v-b-toggle="'contacts-panel'">
          <i class="fas fa-user-circle fa-md text-primary"></i>
          <span class="ml-2" style="vertical-align: center">{{
            title || "Contacts"
            }}</span>

          <i v-if="!isExpanded" class="fas fa-angle-double-down fa-md float-right" title="Click to Expand"></i>
          <i v-if="isExpanded" class="fas fa-angle-double-up fa-md float-right" title="Click to Collapse"></i>
        </h4>

        <b-collapse id="contacts-panel" v-model="isExpanded"
          v-if="Array.isArray(allContacts) && allContacts.length > 0">
          <!-- ROW 1 -->
          <div class="form-group row mb-4 mt-4" v-if="crmEntity && crmEntity.name">
            <label class="col-2 text-right">{{ crmEntity.type }}</label>
            <div class="col-10 font-weight-bold">
              <a v-if="crmEntity && crmEntity.link" :href="crmEntity.link" target="_blank">{{ crmEntity.name }}</a>
              <span v-if="!crmEntity || !crmEntity.link">{{
                crmEntity.name
                }}</span>
            </div>
          </div>

          <!-- ROW 2 -->
          <div class="form-group row">
            <label class="col-md-2 col-12 text-right">
              <span class="mt-3 d-inline-block">Select Contacts<span class="text-danger" v-if="contactType !== 'Supplier'">*</span></span>
            </label>
            <div class="col-md-6 col-12">
            <v-autocomplete
              ref="autocomplete"
              v-model="localSelectedContacts"
              :items="allContactsMinusSelected"
              item-text="K_DisplayName"
              return-object
              multiple
              dense
              chips
              deletable-chips
              small-chips
              :disabled="isReadOnly"
              :search-input.sync="search"
              :filter="customFilter"
              clearable
              hint="Start typing to search contacts"
              persistent-hint
              hide-selected
              @change="clearSearch"
            >
            <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.K_DisplayName }}
                    <span v-if="item.K_Role" class="role-text">({{ item.K_Role }})</span>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            </div>
            <div class="col-md-auto col-12 my-2">
              <button class="btn btn-primary text-center btn-responsive" v-if="!isReadOnly"
                @click.prevent="addToSelectedContacts">
                <i class="fas fa-check mr-2" title="Select"></i> Select
              </button>
            </div>
            <div class="col-md-auto col-12 my-2">
              <button class="btn btn-outline-primary text-center btn-responsive" v-if="!isReadOnly"
                @click.prevent="openAddContactDialog">
                <i class="fas fa-plus mr-2" title="Add New Contact"></i>
                Add New Contact
              </button>
            </div>
          </div>

          <h6 class="mb-5 font-weight-bold">Selected {{ title }}:</h6>
          <span v-if="
              !Array.isArray(filteredSelectedContacts) ||
              filteredSelectedContacts.length === 0
            ">None</span>
          <div class="form-group" v-if="
              Array.isArray(filteredSelectedContacts) &&
              filteredSelectedContacts.length > 0
            ">
            <table class="table table-bordered table-sm text-center align-middle">
              <tr>
                <th>No.</th>
                <th>Name</th>
                <th>Type</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Mobile</th>
                <th>Job Title</th>
                <th>Role</th>
                <th v-if="allowPrimarySelection && !hidePrimaryColumn">Primary</th>
                <th style="color:red;">Remove</th>
              </tr>
              <tr v-for="(contact, index) in filteredSelectedContacts" :key="contact.id">
                <td class="align-middle">{{ index + 1 }}</td>
                <td class="align-middle">{{ contact.Name }}</td>
                <td class="align-middle">{{ contact.K_ContactType }}</td>
                <td class="align-middle">
                  <a :href="'mailto:' + contact.Email + '?subject=' + appData.claim.Name">{{ contact.Email }}</a>
                </td>
                <td class="align-middle">{{ contact.K_Phone }}</td>
                <td class="align-middle">{{ contact.K_Mobile }}</td>
                <td class="align-middle">{{ contact.K_JobTitle }}</td>
                <td class="align-middle">{{ contact.K_Role }}</td>
                <td v-if="allowPrimarySelection && !hidePrimaryColumn" class="align-middle">
                  <input type="checkbox"
                         :checked="contact.K_Primary"
                         @change="setPrimaryContact(contact)"
                         :disabled="isReadOnly" />
                </td>
                <td class="align-middle">
                  <button class="btn btn-icon" @click.prevent="unselectContact(contact)" :disabled="isReadOnly">
                    <i class="fas fa-xmark-circle text-danger" :class="{ 'text-danger': !isReadOnly, 'text-muted': isReadOnly }"></i>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </b-collapse>

        <!-- Hidden input to validate if at least 1 contact is selected -->
        <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }" name="atleast1ContactSelected"
          v-slot="{ errors }">
          <input type="hidden" v-model="atleast1ContactSelected" />
          <span class="text-danger">{{
            errors[0] ? "At least 1 contact must be selected" : ""
            }}</span>
        </ValidationProvider>

        <!-- Hidden input to validate if primary contact has been selected -->
        <ValidationProvider v-if="allowPrimarySelection && !hidePrimaryColumn && atleast1ContactSelected"
          :rules="{ 'required-action': { allowFalse: false } }" name="primaryContactSelected" v-slot="{ errors }">
          <input type="hidden" v-model="primaryContactSelected" />
          <span class="text-danger">{{
            errors[0]
            ? "Please mark one of the contacts as Primary contact"
            : ""
            }}</span>
        </ValidationProvider>
      </b-card>

      <b-modal ref="addNewContactDialog" size="lg" no-close-on-backdrop centered hide-footer>
        <template #modal-title>
          <span>Add New Contact</span>
        </template>
        <div class="form-group col-12 m-0">
          <ValidationObserver ref="addNewContactDialogData">
            <div class="row">
              <div class="col-4">
                <label class="col-form-label">Contact Type<span class="text-danger">*</span></label>
                <ValidationProvider rules="required" name="K_ContactType" v-slot="{ errors }">
                  <b-form-select class="form-control" v-model="newContact.K_ContactType" :disabled="!!contactType">
                    <option :value="null">-- Select ---</option>
                    <option value="Strata Company">Strata Company</option>
                    <option value="Insurer">Insurer</option>
                    <option value="Supplier">Supplier</option>
                    <option value="Individuals">Individuals</option>
                  </b-form-select>
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-4">
                <label class="col-form-label">Company<span class="text-danger">*</span></label>
                <div v-if="newContact.K_ContactType === 'Strata Company'">
                  <v-select v-model="newContact.relatedEntity" :items="refData.allStrataCompanies" item-text="Name"
                    :disabled="!!contactType" small-chips return-object dense>
                  </v-select>
                </div>
                <div v-else-if="newContact.K_ContactType === 'Insurer'">
                  <v-select v-model="newContact.relatedEntity" :items="refData.allInsurers" item-text="Name"
                    :disabled="!!contactType" small-chips return-object dense>
                  </v-select>
                </div>
                <div v-else>
                  <ValidationProvider rules="required|max:255" name="K_Company" v-slot="{ errors }">
                    <input class="form-control" type="text" maxlength="255" placeholder="Enter Company"
                      v-model="newContact.K_Company" />
                    <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                  </ValidationProvider>
                </div>
              </div>
              <div class="col-4">
                <label class="col-form-label">Role <span class="text-danger">*</span></label>
                <ValidationProvider rules="required" name="K_Role" v-slot="{ errors }">
                  <b-form-select class="form-control" v-model="newContact.K_Role" :options="roleOptions">
                  </b-form-select>
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-6">
                <label class="col-form-label">First Name <span class="text-danger">*</span></label>
                <ValidationProvider rules="required|max:255" name="K_FirstName" v-slot="{ errors }">
                  <input class="form-control" type="text" maxlength="255" placeholder="Enter First Name"
                    v-model="newContact.K_FirstName" />
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-6">
                <label class="col-form-label">Last Name <span class="text-danger">*</span></label>
                <ValidationProvider rules="required|max:255" name="K_LastName" v-slot="{ errors }">
                  <input class="form-control" type="text" maxlength="255" placeholder="Enter Last Name"
                    v-model="newContact.K_LastName" />
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-6">
                <label class="col-form-label">Email <span class="text-danger">*</span></label>
                <ValidationProvider rules="required|max:100" name="Email" v-slot="{ errors }">
                  <input class="form-control" type="email" maxlength="100" placeholder="Enter Email"
                    v-model="newContact.Email" />
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-6">
                <label class="col-form-label">Phone <span class="text-danger">*</span></label>
                <ValidationProvider rules="required|max:30" name="K_Phone" v-slot="{ errors }">
                  <input class="form-control" type="text" maxlength="30" placeholder="Enter Phone Number"
                    v-model="newContact.K_Phone" />
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-6">
                <label class="col-form-label">Mobile</label>
                <input class="form-control" type="text" maxlength="30" placeholder="Enter Mobile Number"
                  v-model="newContact.K_Mobile" />
              </div>
              <div class="main-from col-6">
                <label class="col-form-label">Job Title</label>
                <ValidationProvider rules="max:255" name="K_JobTitle" v-slot="{ errors }">
                  <input class="form-control" type="text" maxlength="255" placeholder="Enter Job Title"
                    v-model="newContact.K_JobTitle" />
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-12">
                <label class="col-form-label">Comments</label>
                <ValidationProvider rules="max:2000" name="K_OtherDetails" v-slot="{ errors }">
                  <b-textarea class="form-control" rows="2" placeholder="Add Comments"
                    v-model="newContact.K_OtherDetails" trim></b-textarea>
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="col-12 d-flex justify-content-end mt-4">
                <div class="row">
                  <button type="submit" class="btn btn-primary mr-4" @click.prevent="addNewContact"
                    :disabled="savingData">
                    Save
                  </button>
                  <b-spinner v-if="savingData" class="mt-2" variant="primary"></b-spinner>
                  <button type="reset" class="btn btn-danger" @click.prevent="closeAddContactDialog"
                    :disabled="savingData">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </div>
      </b-modal>
    </div>
  </ValidationObserver>
</template>

<style lang="scss" scoped>
.role-text {
  color: #666;
}
</style>

<script>
import _ from "lodash";

export default {
  name: "ClaimContacts",

  props: {
    appData: Object,
    title: String,
    contactType: String,
    allContacts: Array,
    crmEntity: Object,
    isReadOnly: Boolean,
    refData: Object,
    allowPrimarySelection: {
      type: Boolean,
      default: true,
    },
    hidePrimaryColumn: {
      type: Boolean,
      default: false
    },
    filterContacts: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {
      isExpanded: true,
      savingData: false,
      localSelectedContacts: [],
      newContact: {},
      filteredSelectedContacts: [],
      search: '',
    };
  },

  mounted() {
      this.$emit('mounted', this);
      this.$nextTick(() => {
        this.setFilteredSelectedContacts();
      });
  },

  watch: {
    claimContactsLength: function (/* newVal, oldVal */) {
      this.setFilteredSelectedContacts();
    },

    'appData.claim.K_ClaimContacts': {
        handler(newContacts, oldContacts) {
          this.$nextTick(() => {
            this.setFilteredSelectedContacts();
          });
        },
        deep: true
      },

      contactType: {
        immediate: true,
        handler(newValue, oldValue) {
          if (newValue) {
            this.setFilteredSelectedContacts();
          }
        }
      },

      allContacts: {
          handler(newContacts) {
            this.setFilteredSelectedContacts();
          },
          deep: true
        },

        localSelectedContacts: {
            handler() {
              this.clearSearch();
            },
            deep: true
          }
  },

  methods: {
    clearSearch() {
        this.$nextTick(() => {
          if (this.$refs.autocomplete) {
            this.$refs.autocomplete.search = '';
            this.search = '';
          }
        });
      },

      setPrimaryContact: function (selectedContact) {
          if (this.isReadOnly) return;

          this.filteredSelectedContacts.forEach(contact => {
            this.$set(contact, 'K_Primary', contact.id === selectedContact.id);
          });

          this.saveContacts();
          this.$emit('primary-contact-changed', selectedContact.id);
        },

      customFilter(item, queryText, _) {
          const searchText = queryText.toLowerCase().trim();
          const contactName = item.K_DisplayName.toLowerCase();
          const contactEmail = (item.Email || '').toLowerCase();
          const contactPhone = (item.K_Phone || '').toLowerCase();
          const contactMobile = (item.K_Mobile || '').toLowerCase();

          return contactName.includes(searchText) ||
                 contactEmail.includes(searchText) ||
                 contactPhone.includes(searchText) ||
                 contactMobile.includes(searchText);
        },

    saveContacts: function () {
      if (this.isReadOnly) {
        return;
      }

      this.savingData = true;
      this.$claimDataService
        .saveClaimContacts(this.appData.claim.K_ClaimContacts)
        .then((/*{ data }*/) => {
          this.savingData = false;
        })
        .catch((error) => {
          this.savingData = false;
          this.$emit("handleError", error);
        });
    },

    openAddContactDialog: function () {
      if (this.isReadOnly) {
        return;
      }

      this.newContact = {
        K_ContactType: this.contactType,
      };
      if (
        this.crmEntity &&
        (this.contactType === "Strata Company" ||
          this.contactType === "Insurer")
      ) {
        let allEntities =
          this.contactType === "Strata Company"
            ? this.refData.allStrataCompanies
            : this.refData.allInsurers;

        let relatedEntity = allEntities.find((entity) => {
          return entity.id === this.crmEntity.id;
        });
        if (relatedEntity) {
          this.newContact.relatedEntity = relatedEntity;
        }
      }
      this.$refs["addNewContactDialog"].show();
    },

    closeAddContactDialog: function () {
      this.$refs["addNewContactDialog"].hide();
      this.newContact = {};
    },

    addNewContact: function () {
      if (this.isReadOnly) {
        return;
      }

      this.$refs.addNewContactDialogData.validate().then((isValid) => {
        if (isValid) {
          this.savingData = true;
          this.$claimDataService
            .addClaimContact(this.newContact)
            .then(({ data }) => {
              let addedContact = data.result;
              if (addedContact) {
                this.appData.claim.K_ClaimContacts.push(addedContact);
              }
              this.setFilteredSelectedContacts();

              // Close the dialog and clear the data
              this.newContact = {};
              this.savingData = false;
              this.$refs["addNewContactDialog"].hide();
            })
            .catch((error) => {
              this.savingData = false;
              this.$emit("handleError", error);
            });
        }
      });
    },

    addToSelectedContacts() {
      if (this.localSelectedContacts.length > 0) {
        let updatedContacts = [...this.appData.claim.K_ClaimContacts];

        this.localSelectedContacts.forEach((contact) => {
          if (!updatedContacts.some(c => c.id === contact.id)) {
            updatedContacts.push({
              ...contact,
              K_ContactType: this.filterContacts ?
                (contact.K_ContactType === 'Underwriter' ? 'Underwriter' : this.contactType) :
                contact.K_ContactType,
              K_Primary: false
            });
          }
        });

        this.$set(this.appData.claim, 'K_ClaimContacts', updatedContacts);
        this.setFilteredSelectedContacts();
        this.saveContacts();
        this.localSelectedContacts = [];
      }
    },

    unselectContact: function (contact) {
      if (this.isReadOnly) {
        return; // Don't do anything if in read-only mode
      }
      let index = _.findIndex(this.appData.claim.K_ClaimContacts, {
        id: contact.id,
      });
      if (index >= 0) {
        this.appData.claim.K_ClaimContacts.splice(index, 1);
        this.setFilteredSelectedContacts();

        // Save claim contacts to server
        this.saveContacts();
      }
    },

    setFilteredSelectedContacts() {
      if (this.filterContacts) {
        if (!this.contactType) {
          console.error('Contact type is undefined');
          return;
        }

        this.filteredSelectedContacts = this.appData.claim.K_ClaimContacts.filter(contact => {
          return contact.K_ContactType === this.contactType ||
                 (this.contactType === 'Insurer' && contact.K_ContactType === 'Underwriter') ||
                 (this.contactType === 'Supplier' && contact.K_ContactType === 'Supplier');
        });
      } else {
        // If not filtering, show all contacts
        this.filteredSelectedContacts = this.appData.claim.K_ClaimContacts;
      }

      // Ensure K_Primary is set for each contact
      this.filteredSelectedContacts.forEach(contact => {
        if (!contact.hasOwnProperty('K_Primary')) {
          this.$set(contact, 'K_Primary', false);
        }
      });

      this.$forceUpdate();
    },
  },

  computed: {
    primaryContactId: {
        get() {
          const primaryContact = this.filteredSelectedContacts.find(c => c.K_Primary);
          return primaryContact ? primaryContact.id : null;
        },
        set(id) {
          this.filteredSelectedContacts.forEach(c => {
            this.$set(c, 'K_Primary', c.id === id);
          });
          this.saveContacts();
        }
    },
    claimContactsLength: function () {
      if (this.appData) {
        return this.appData.claim.K_ClaimContacts.length;
      }
      return 0;
    },

    allContactsMinusSelected() {
      if (this.filterContacts && !this.contactType) {
        console.error('Contact type is undefined in allContactsMinusSelected');
        return [];
      }
      const selectedIds = new Set(this.filteredSelectedContacts.map(c => c.id));
      return this.allContacts.filter(contact =>
        !selectedIds.has(contact.id) &&
        (!this.filterContacts ||
         contact.K_ContactType === this.contactType ||
         (this.contactType === 'Insurer' && contact.K_ContactType === 'Underwriter') ||
         (this.contactType === 'Supplier' && contact.K_ContactType === 'Supplier'))
      );
    },

    roleOptions: function () {
      let options = [];
      if (this.newContact.K_ContactType) {
        switch (this.newContact.K_ContactType) {
          case "Strata Company": {
            options = [
              "Strata Manager",
              "Branch Manager",
              "Director/Principal",
              "Insurance Manager",
              "Claims Manager",
              "Strata Support",
            ];
            break;
          }
          case "Insurer": {
            options = [
              "Underwriter",
              "Claims Consultant",
              "Management",
              "Admin Support",
              "Complaints Specialist",
              "BDM",
            ];
            break;
          }
          case "Supplier": {
            options = [
              "Builder",
              "Loss Adjustor",
              "Consultant",
              "Restorer",
              "Contractor",
              "Solicitor",
              "Recovery Agent",
            ];
            break;
          }
          case "Individuals": {
            options = [
              "Lot Owner",
              "Committee Member",
              "Building Manager",
              "Property Manager",
              "Tenant",
              "Authorised Rep",
              "Third Party",
              "Claimant/Applicant",
              "Other Party Solicitor",
            ];
            break;
          }
        }
      }
      return options;
    },

    atleast1ContactSelected: function () {
      if (this.contactType === 'Supplier') {
          return true; // Always return true for suppliers
        }
      return this.filteredSelectedContacts.length > 0;
    },

    primaryContactSelected: function () {
      if (this.contactType === 'Supplier') {
        return true; // Always return true for suppliers
      }
      if (this.atleast1ContactSelected && !this.hidePrimaryColumn) {
        return this.filteredSelectedContacts.some(contact => contact.K_Primary);
      }
      return true;
    },
},
};
</script>

<style lang="scss" scoped>
@media (min-width: 1094px) {
  /* Adjust breakpoint as needed */
  .btn-responsive {
    min-width: 120px; /* Adjust minimum button width as needed */
  }
}
@media (max-width: 1093px) {
  /* Adjust breakpoint as needed */
  .btn-responsive {
    width: 100%;
  }
}

::v-deep .v-list-item__mask {
  background-color: #FFFF00 !important; // Bright yellow
  opacity: 1;
}
</style>
