<template>
    <ValidationObserver ref="damageMgmt">
        <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
            <h4 class="font-weight-bold text-dark" v-b-toggle="'damages-panel'">
                <i class="fas fa-user-circle fa-md text-primary"></i>
                <span class="ml-2" style="vertical-align: center">Claim Damages</span>

                <i v-if="!isExpanded" class="fas fa-angle-double-down fa-md float-right" title="Click to Expand"></i>
                <i v-if="isExpanded" class="fas fa-angle-double-up fa-md float-right" title="Click to Collapse"></i>
            </h4>

            <b-collapse id="damages-panel" v-model="isExpanded" v-if="appData">
                <table class="table table-bordered">
                    <tr>
                        <th class="text-center">Name</th>
                        <th class="text-center">Description</th>
                        <th class="text-center">Date</th>
                        <th class="text-center">Lot Owner</th>
                        <th class="text-center">Unit Number</th>
                        <th class="text-center">Status</th>
                    </tr>
                    <tr v-for="(damage, index) in appData.claimDamages" :key="index">
                        <td class="text-center">{{ damage.Name }}</td>
                        <td>{{ damage.K_DamageDescription }}</td>
                        <td class="text-center">
                            <span v-if="damage.K_DamageDate">{{
                                damage.K_DamageDate
                                | dateParse("YYYY-MM-DD")
                                | dateFormat("DD/MM/YYYY")
                            }}</span>
                        </td>
                        <td class="text-center">{{ damage.K_LotOwner }}</td>
                        <td class="text-center">{{ damage.K_UnitNumber }}</td>
                        <td class="text-center">{{ damage.K_DamageStatus }}</td>
                        <td class="text-center" v-if="!isReadOnly" @click.prevent="openEditDamageDialog(index)">
                            <i class="fas fa-pencil-alt fa-md text-primary cursor-pointer"></i>
                        </td>
                        <td class="text-center" v-if="!isReadOnly" @click.prevent="deleteDamage(index)">
                            <i class="fas fa-times-circle fa-md text-danger cursor-pointer"></i>
                        </td>
                    </tr>
                </table>
                <button type="button" v-if="!isReadOnly" @click.prevent="openAddDamageDialog"
                    class="btn btn-primary font-weight-bold px-4 py-3 mt-5">
                    Add New Damage
                </button>
            </b-collapse>
            <!-- Hidden input to validate if at least 1 record is created -->
            <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }" name="atleast1DamageCreated"
                v-slot="{ errors }">
                <input type="hidden" v-model="atleast1RecordCreated" />
                <span class="text-danger">{{
                    errors[0] ? "At least 1 Claim Damage must be created" : ""
                }}</span>
            </ValidationProvider>
        </b-card>

        <b-modal ref="addEditDamageDialog" size="lg" no-close-on-backdrop centered hide-footer>
            <template #modal-title>
                <span v-if="damageBeingEdited && damageBeingEdited.id">Edit Damage Data</span>
                <span v-if="damageBeingEdited && !damageBeingEdited.id">Add Claim Damage</span>
            </template>
            <div class="form-group" v-if="damageBeingEdited">
                <ValidationObserver ref="addNewDamageDialogData">
                    <div class="row">
                        <div class="col-6">
                            <label class="col-form-label">Damage Date <span class="text-danger">*</span></label>
                            <ValidationProvider rules="required" name="K_DamageDate" v-slot="{ errors }">
                                <b-form-datepicker class="form-control" :locale="'en-AU'"
                                    v-model="damageBeingEdited.K_DamageDate" :date-format-options="{
                                        year: 'numeric',
                                        month: '2-digit',
                                        day: '2-digit',
                                    }" :max="new Date()">
                                </b-form-datepicker>
                                <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-6">
                            <label class="col-form-label">Damage Status <span class="text-danger">*</span></label>
                            <ValidationProvider rules="required" name="K_DamageStatus" v-slot="{ errors }">
                                <b-form-select class="form-control" v-model="damageBeingEdited.K_DamageStatus">
                                    <option :value="null">-- Select ---</option>
                                    <option value="Not Started">Not Started</option>
                                    <option value="Awaiting Assessment">
                                        Awaiting Assessment
                                    </option>
                                    <option value="Repairs In Progress">
                                        Repairs In Progress
                                    </option>
                                    <option value="Repairs Completed">Repairs Completed</option>
                                    <option value="Awaiting maintenance">Awaiting maintenance</option>
                                    <option value="Awaiting Insured quote">Awaiting Insured quote</option>
                                    <option value="Awaiting signed contracts & excess">Awaiting signed contracts & excess
                                    </option>
                                    <option value="Awaiting instructions from insured">Awaiting instructions from insured
                                    </option>
                                    <option value="Damage declined">Damage declined</option>
                                    <option value="Repairs cash settled">Repairs cash settled</option>
                                    <option value="Repairs on hold">Repairs on hold</option>
                                    <option value="Loss quantified">Loss quantified</option>
                                    <option value="Loss not quantified">Loss not quantified</option>
                                    <option value="Liability accepted">Liability accepted</option>
                                    <option value="Liability rejected">Liability rejected</option>
                                </b-form-select>
                                <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-6">
                            <label class="col-form-label">Damage Description <span class="text-danger">*</span></label>
                            <ValidationProvider rules="required|max:255" name="K_DamageDescription" v-slot="{ errors }">
                                <input class="form-control" maxlength="255" placeholder="Add Description"
                                    v-model="damageBeingEdited.K_DamageDescription" />
                                <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-6">
                            <label class="col-form-label">Lot Owner <span class="text-danger">*</span></label>
                            <ValidationProvider rules="required|max:255" name="K_LotOwner" v-slot="{ errors }">
                                <input class="form-control" type="text" placeholder="Lot Owner"
                                    v-model="damageBeingEdited.K_LotOwner" />
                                <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-6">
                            <label class="col-form-label">Unit Number <span class="text-danger"></span></label>
                            <ValidationProvider rules="max:255" name="K_UnitNumber" v-slot="{ errors }">
                                <input class="form-control" type="text" placeholder="Unit Number"
                                    v-model="damageBeingEdited.K_UnitNumber" />
                                <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-12">
                            <label class="col-form-label">Damage Details <span class="text-danger">*</span></label>
                            <ValidationProvider rules="required|max:2000" name="K_DamageDetails" v-slot="{ errors }">
                                <b-textarea class="form-control" rows="2" placeholder="Add Description"
                                    v-model="damageBeingEdited.K_DamageDetails" trim></b-textarea>
                                <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                        <div class="col-12 d-flex justify-content-end mt-4">
                            <div class="row">
                                <button type="submit" class="btn btn-primary" @click.prevent="saveDamage"
                                    :disabled="savingData">
                                    Submit
                                </button>
                                <b-spinner v-if="savingData" class="mt-2" variant="primary"></b-spinner>
                                <button type="reset" class="btn btn-danger ml-3" @click.prevent="cancelDialog"
                                    :disabled="savingData">
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </b-modal>
    </ValidationObserver>
</template>

<style lang="scss">
</style>

<script>
import _ from "lodash";
import Swal from "sweetalert2";

export default {
    name: "ClaimDamages",

    props: {
        appData: Object,
        isReadOnly: Boolean,
    },

    data() {
        return {
            isExpanded: true,
            damageBeingEdited: null,
            savingData: false,
        };
    },

    methods: {
        openAddDamageDialog: function () {
            this.damageBeingEdited = {
                K_DamageStatus: "Not Started",
                K_DamageDate: this.appData.claim.K_DateOfLoss,
            };
            this.$refs["addEditDamageDialog"].show();
        },

        openEditDamageDialog: function (index) {
            this.damageBeingEdited = _.cloneDeep(this.appData.claimDamages[index]);
            this.damageBeingEdited.index = index;
            this.$refs["addEditDamageDialog"].show();
        },

        cancelDialog: function () {
            this.$refs["addEditDamageDialog"].hide();

            this.$nextTick(() => {
                this.damageBeingEdited = {};
            });
        },

        saveDamage: function () {
            this.$refs.addNewDamageDialogData.validate().then((isValid) => {
                if (isValid) {
                    this.savingData = true;
                    this.$claimDataService
                        .updateClaimDamage(this.damageBeingEdited)
                        .then(({ data }) => {
                            if (data && data.result) {
                                let newDamage = data.result;
                                if (this.damageBeingEdited.hasOwnProperty("index")) {
                                    let index = this.damageBeingEdited.index;
                                    this.$set(this.appData.claimDamages, index, newDamage);
                                } else {
                                    if (!Array.isArray(this.appData.claimDamages)) {
                                        this.$set(this.appData, 'claimDamages', []);
                                    }
                                    this.appData.claimDamages.push(newDamage);
                                }

                                this.$refs["addEditDamageDialog"].hide();
                                this.$nextTick(() => {
                                    this.damageBeingEdited = {};
                                });
                                this.savingData = false;
                                // Emit an event to notify the parent component
                                this.$emit('damages-updated', this.appData.claimDamages);
                            }
                        })
                        .catch((error) => {
                            this.savingData = false;
                            this.$refs["addEditDamageDialog"].hide();
                            this.$emit("handleError", error);
                        });
                }
            });
        },

        deleteDamage: function (index) {
            Swal.fire({
                title: "Delete Claim Damage",
                text: "Are you sure you want to delete this Damage record?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.isConfirmed) {
                    let damage = this.appData.claimDamages[index];
                    this.savingData = true;
                    this.$claimDataService
                        .deleteClaimDamage(damage.id)
                        .then(() => {
                            this.appData.claimDamages.splice(index, 1);
                            this.savingData = false;
                            // Emit an event to notify the parent component
                            this.$emit('damages-updated', this.appData.claimDamages);
                        })
                        .catch((error) => {
                            this.savingData = false;
                            this.$emit("handleError", error);
                        });
                }
            });
        },
    },

    computed: {
        atleast1RecordCreated: function () {
            return (
                Array.isArray(this.appData.claimDamages) &&
                this.appData.claimDamages.length > 0
            );
        },
    },
};
</script>
