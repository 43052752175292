<template>
  <ValidationObserver ref="settlementsMgmt">
    <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
      <h4 class="font-weight-bold text-dark" v-b-toggle="'settlements-panel'">
        <i class="fas fa-user-circle fa-md text-primary"></i>
        <span class="ml-2" style="vertical-align: center">Claim Settlements</span>

        <i v-if="!isExpanded" class="fas fa-angle-double-down fa-md float-right" title="Click to Expand"></i>
        <i v-if="isExpanded" class="fas fa-angle-double-up fa-md float-right" title="Click to Collapse"></i>
      </h4>

      <b-collapse id="settlements-panel" v-model="isExpanded" v-if="appData">
        <table class="table table-bordered">
          <tr>
            <th class="text-center">Name</th>
            <th class="text-center">Description</th>
            <th class="text-center">Related Damages</th>
            <th class="text-center">Payee Type</th>
            <th class="text-center">Date Paid</th>
            <th class="text-center">Amount Paid</th>
            <th class="text-center">Excess Deducted</th>
            <th class="text-center">GST Deducted</th>
          </tr>
          <tr v-for="(settlement, index) in appData.claimSettlements" :key="index">
            <td class="text-center">{{ settlement.Name }}</td>
            <td>{{ settlement.K_SettlementDescription }}</td>
            <td>
              <span v-if="
                  Array.isArray(settlement.K_RelatedDamages) &&
                  settlement.K_RelatedDamages.length > 0
                ">
                <b-badge class="mr-2" variant="secondary" v-for="(dmgId, i) in settlement.K_RelatedDamages" :key="i">
                  {{ getDamageName(dmgId) }}
                </b-badge>
              </span>
            </td>
            <td class="text-center">{{ settlement.K_PayeeType }}</td>
            <td class="text-center">
              <span v-if="settlement.K_DatePaid">{{
                settlement.K_DatePaid
                | dateParse("YYYY-MM-DD")
                | dateFormat("DD/MM/YYYY")
                }}</span>
            </td>
            <td>{{ settlement.K_AmountPaid | currency }}</td>
            <td class="text-center">{{ settlement.K_ExcessDeducted }}</td>
            <td class="text-center">{{ settlement.K_GstDeducted }}</td>
            <td class="text-center" v-if="!isReadOnly" @click.prevent="openEditSettlementDialog(index)">
              <i class="fas fa-pencil-alt fa-md text-primary cursor-pointer"></i>
            </td>
            <td class="text-center" v-if="!isReadOnly" @click.prevent="deleteSettlement(index)">
              <i class="fas fa-times-circle fa-md text-danger cursor-pointer"></i>
            </td>
          </tr>
        </table>
        <button type="button" v-if="!isReadOnly" @click.prevent="openAddSettlementDialog"
          class="btn btn-primary font-weight-bold px-4 py-3 mt-5">
          Add New Settlement
        </button>
      </b-collapse>
      <!--      &lt;!&ndash; Hidden input to validate if at least 1 record is created &ndash;&gt;-->
      <!--      <ValidationProvider :rules="{ 'required-action': { allowFalse: false } }" name="atleast1SettlementCreated" v-slot="{ errors }">-->
      <!--        <input type="hidden" v-model="atleast1RecordCreated">-->
      <!--        <span class="text-danger">{{ errors[0] ? 'At least 1 Claim Settlement must be created' : ''}}</span>-->
      <!--      </ValidationProvider>-->
    </b-card>

    <b-modal ref="addEditSettlementDialog" size="lg" no-close-on-backdrop centered hide-footer>
      <template #modal-title>
        <span v-if="settlementBeingEdited && settlementBeingEdited.id">Edit Settlement Data</span>
        <span v-if="settlementBeingEdited && !settlementBeingEdited.id">Add Claim Settlement</span>
      </template>
      <div class="form-group" v-if="settlementBeingEdited">
        <ValidationObserver ref="addNewSettlementDialogData">
          <div class="row">
            <div class="col-6">
              <label class="col-form-label">Settlement Description
                <span class="text-danger">*</span></label>
              <ValidationProvider rules="required|max:255" name="K_SettlementDescription" v-slot="{ errors }">
                <input class="form-control" maxlength="255" placeholder="Add Description"
                  v-model="settlementBeingEdited.K_SettlementDescription" />
                <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-6">
              <label class="col-form-label">Date Paid <span class="text-danger">*</span></label>
              <ValidationProvider rules="required" name="K_DatePaid" v-slot="{ errors }">
                <b-form-datepicker class="form-control" :locale="'en-AU'" v-model="settlementBeingEdited.K_DatePaid"
                  :date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  }" :max="new Date()">
                </b-form-datepicker>
                <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-6">
              <label class="col-form-label">Amount Paid <span class="text-danger">*</span></label>
              <ValidationProvider rules="required" name="K_AmountPaid" v-slot="{ errors }">
                <currency-input class="form-control" v-model="settlementBeingEdited.K_AmountPaid" />
                <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-6">
              <label class="col-form-label">Excess Deducted? <span class="text-danger">*</span></label>
              <ValidationProvider rules="required" name="K_ExcessDeducted" v-slot="{ errors }">
                <b-form-select class="form-control" v-model="settlementBeingEdited.K_ExcessDeducted">
                  <option :value="null">-- Select ---</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </b-form-select>
                <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-6">
              <label class="col-form-label">GST Deducted? <span class="text-danger">*</span></label>
              <ValidationProvider rules="required" name="K_GstDeducted" v-slot="{ errors }">
                <b-form-select class="form-control" v-model="settlementBeingEdited.K_GstDeducted">
                  <option :value="null">-- Select ---</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </b-form-select>
                <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-6">
              <label class="col-form-label">Payee Type <span class="text-danger">*</span></label>
              <ValidationProvider rules="required" name="K_PayeeType" v-slot="{ errors }">
                <b-form-select class="form-control" v-model="settlementBeingEdited.K_PayeeType">
                  <option :value="null">-- Select ---</option>
                  <option value="OC">OC</option>
                  <option value="LO">LO</option>
                </b-form-select>
                <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-12 mt-3">
              <label class="col-form-label">Related Damages</label>
              <v-select v-model="settlementBeingEdited.K_RelatedDamages" :items="appData.claimDamages"
                :item-text="getDamageLabel" item-value="id" label="Select" multiple clearable dense chips>
              </v-select>
            </div>
            <div class="col-12">
              <label class="col-form-label">Settlement Details <span class="text-danger">*</span></label>
              <ValidationProvider rules="required|max:2000" name="K_SettlementDetails" v-slot="{ errors }">
                <b-textarea class="form-control" rows="2" placeholder="Add Description"
                  v-model="settlementBeingEdited.K_SettlementDetails" trim></b-textarea>
                <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div class="col-12 d-flex justify-content-end mt-4">
              <div class="row">
                <button type="submit" class="btn btn-primary" @click.prevent="saveSettlement" :disabled="savingData">
                  Submit
                </button>
                <b-spinner v-if="savingData" class="mt-2" variant="primary"></b-spinner>
                <button type="reset" class="btn btn-danger ml-3" @click.prevent="cancelDialog" :disabled="savingData">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>
    </b-modal>
  </ValidationObserver>
</template>

<style lang="scss"></style>

<script>
import _ from "lodash";
import Swal from "sweetalert2";

export default {
  name: "ClaimSettlements",

  props: {
    appData: Object,
    isReadOnly: Boolean,
  },

  data() {
    return {
      isExpanded: true,
      settlementBeingEdited: null,
      savingData: false,
    };
  },

  methods: {
    openAddSettlementDialog: function () {
      this.settlementBeingEdited = {};
      this.$refs["addEditSettlementDialog"].show();
    },

    openEditSettlementDialog: function (index) {
      this.settlementBeingEdited = _.cloneDeep(
        this.appData.claimSettlements[index]
      );
      this.settlementBeingEdited.index = index;
      this.$refs["addEditSettlementDialog"].show();
    },

    cancelDialog: function () {
      this.$refs["addEditSettlementDialog"].hide();

      this.$nextTick(() => {
        this.settlementBeingEdited = {};
      });
    },

    saveSettlement: function () {
      this.$refs.addNewSettlementDialogData.validate().then((isValid) => {
        if (isValid) {
          this.savingData = true;
          this.$claimDataService
            .updateClaimSettlement(this.settlementBeingEdited)
            .then(({ data }) => {
              if (data && data.result) {
                let newSettlement = data.result;
                if (this.settlementBeingEdited.hasOwnProperty("index")) {
                  let index = this.settlementBeingEdited.index;
                  this.$emit('update:claimSettlements', [
                    ...this.appData.claimSettlements.slice(0, index),
                    newSettlement,
                    ...this.appData.claimSettlements.slice(index + 1)
                  ]);
                } else {
                  this.$emit('update:claimSettlements', [
                    ...this.appData.claimSettlements,
                    newSettlement
                  ]);
                }

                this.$refs["addEditSettlementDialog"].hide();
                this.$nextTick(() => {
                  this.settlementBeingEdited = {};
                });
                this.savingData = false;
              }
            })
            .catch((error) => {
              this.savingData = false;
              this.$refs["addEditSettlementDialog"].hide();
              this.$emit("handleError", error);
            });
        }
      });
    },

    deleteSettlement: function (index) {
      Swal.fire({
        title: "Delete Claim Settlement",
        text: "Are you sure you want to delete this Settlement record?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          let settlement = this.appData.claimSettlements[index];
          this.savingData = true;
          this.$claimDataService
            .deleteClaimSettlement(settlement.id)
            .then(() => {
              this.$emit('update:claimSettlements',
                this.appData.claimSettlements.filter((_, i) => i !== index)
              );
              this.savingData = false;
            })
            .catch((error) => {
              this.savingData = false;
              this.$emit("handleError", error);
            });
        }
      });
    },

    getDamageLabel: function (damage) {
      if (damage) {
        return `${damage.Name} ${damage.K_UnitNumber ? '- Unit No: ' + damage.K_UnitNumber : ''} - ${damage.K_DamageDescription}`;
      }
    },

    getDamageName: function (damageId) {
      let damage = _.find(this.appData.claimDamages, { id: damageId });
      return damage ? damage.Name : "";
    },
  },

  // computed: {
  //   atleast1RecordCreated: function () {
  //     return Array.isArray(this.appData.claimSettlements) && this.appData.claimSettlements.length > 0;
  //   }
  // }
};
</script>
