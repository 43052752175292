<template>
  <div>
    <b-card border-variant="primary" body-class="px-4 py-4" class="mt-10">
      <h4 class="font-weight-bold text-dark" v-b-toggle="'followup-panel'">
        <i class="fas fa-sticky-note fa-md text-primary"></i>
        <span class="ml-2" style="vertical-align: center">Claim Follow-up</span>
        <i v-if="!isExpanded" class="fas fa-angle-double-down fa-md float-right" title="Click to Expand"></i>
        <i v-if="isExpanded" class="fas fa-angle-double-up fa-md float-right" title="Click to Collapse"></i>
      </h4>
      <b-collapse id="followup-panel" v-model="isExpanded">
        <div v-if="appData">
          <ValidationObserver ref="followUpDate">
            <div class="form-group row mb-0">
              <label class="col-2 col-form-label">Follow-up Date</label>
              <div class="col-2">
                <ValidationProvider rules="" name="K_FollowUpDate" v-slot="{ errors }">
                  <b-form-datepicker class="form-control" :locale="'en-AU'" reset-button today-button
                    v-model="appData.claim.K_FollowUpDate" placeholder="DD/MM/YYYY" :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }" :disabled="isReadOnly || !isEditMode">
                  </b-form-datepicker>
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <label class="col-2 col-form-label text-right">Follow-up Action</label>
              <div class="col-6">
                <ValidationProvider :rules="{
                    required: !!appData.claim.K_FollowUpDate,
                    max: 2000,
                  }" name="K_FollowUpAction" v-slot="{ errors }">
                  <b-form-textarea class="form-control" size="2000" rows="2" v-model="appData.claim.K_FollowUpAction"
                    :disabled="isReadOnly || !isEditMode">
                  </b-form-textarea>
                  <span v-if="errors.length" class="error-message">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
            </div>
            <div class="form-group mt-2 mb-0" v-if="!isEditMode">
              <button class="btn btn-primary" @click="editData" v-if="!isReadOnly">
                <i class="fa fa-edit"></i> Edit
              </button>
            </div>
            <div class="form-group mt-2 mb-0" v-if="isEditMode">
              <button class="btn btn-primary" @click.prevent="saveData">
                Save
              </button>
              <b-spinner class="ml-3 align-middle" variant="primary" v-if="savingData"></b-spinner>
              <button class="btn btn-secondary ml-3" @click.prevent="cancelEditMode">
                Cancel
              </button>
            </div>
          </ValidationObserver>
        </div>
      </b-collapse>
    </b-card>
  </div>
</template>

<style lang="scss"></style>

<script>
export default {
  name: "ClaimFollowUp",

  props: {
    appData: Object,
    isReadOnly: Boolean,
  },

  data() {
    return {
      isExpanded: true,
      isEditMode: false,
      savingData: false,
      originalData: {},
    };
  },

  methods: {
    editData: function () {
      this.originalData = {
        K_FollowUpDate: this.appData.claim.K_FollowUpDate,
        K_FollowUpAction: this.appData.claim.K_FollowUpAction,
      };
      this.isEditMode = true;
    },

    cancelEditMode: function () {
      this.$set(
        this.appData.claim,
        "K_FollowUpDate",
        this.originalData.K_FollowUpDate
      );
      this.$set(
        this.appData.claim,
        "K_FollowUpAction",
        this.originalData.K_FollowUpAction
      );
      this.originalData = {};
      this.isEditMode = false;
    },

    saveData: function () {
      this.$refs.followUpDate.validate().then((isValid) => {
        if (isValid) {
          this.savingData = true;
          let updatedData = {
            Name: this.appData.claim.Name,
            K_FollowUpDate: this.appData.claim.K_FollowUpDate,
            K_FollowUpAction: this.appData.claim.K_FollowUpAction,
          };
          this.$claimDataService
            .updateFollowUpDate(updatedData)
            .then((/*{data}*/) => {
              this.isEditMode = false;
              this.originalData = {};
              this.savingData = false;
            })
            .catch((error) => {
              this.$emit("handleError", error);
              this.savingData = false;
            });
        }
      });
    },
  },
};
</script>
