var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-card',{staticClass:"mt-10",attrs:{"border-variant":"primary","body-class":"px-4 py-4"}},[_c('h4',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('followup-panel'),expression:"'followup-panel'"}],staticClass:"font-weight-bold text-dark"},[_c('i',{staticClass:"fas fa-sticky-note fa-md text-primary"}),_c('span',{staticClass:"ml-2",staticStyle:{"vertical-align":"center"}},[_vm._v("Claim Follow-up")]),(!_vm.isExpanded)?_c('i',{staticClass:"fas fa-angle-double-down fa-md float-right",attrs:{"title":"Click to Expand"}}):_vm._e(),(_vm.isExpanded)?_c('i',{staticClass:"fas fa-angle-double-up fa-md float-right",attrs:{"title":"Click to Collapse"}}):_vm._e()]),_c('b-collapse',{attrs:{"id":"followup-panel"},model:{value:(_vm.isExpanded),callback:function ($$v) {_vm.isExpanded=$$v},expression:"isExpanded"}},[(_vm.appData)?_c('div',[_c('ValidationObserver',{ref:"followUpDate"},[_c('div',{staticClass:"form-group row mb-0"},[_c('label',{staticClass:"col-2 col-form-label"},[_vm._v("Follow-up Date")]),_c('div',{staticClass:"col-2"},[_c('ValidationProvider',{attrs:{"rules":"","name":"K_FollowUpDate"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-datepicker',{staticClass:"form-control",attrs:{"locale":'en-AU',"reset-button":"","today-button":"","placeholder":"DD/MM/YYYY","date-format-options":{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  },"disabled":_vm.isReadOnly || !_vm.isEditMode},model:{value:(_vm.appData.claim.K_FollowUpDate),callback:function ($$v) {_vm.$set(_vm.appData.claim, "K_FollowUpDate", $$v)},expression:"appData.claim.K_FollowUpDate"}}),(errors.length)?_c('span',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,3635660792)})],1),_c('label',{staticClass:"col-2 col-form-label text-right"},[_vm._v("Follow-up Action")]),_c('div',{staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"rules":{
                  required: !!_vm.appData.claim.K_FollowUpDate,
                  max: 2000,
                },"name":"K_FollowUpAction"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('b-form-textarea',{staticClass:"form-control",attrs:{"size":"2000","rows":"2","disabled":_vm.isReadOnly || !_vm.isEditMode},model:{value:(_vm.appData.claim.K_FollowUpAction),callback:function ($$v) {_vm.$set(_vm.appData.claim, "K_FollowUpAction", $$v)},expression:"appData.claim.K_FollowUpAction"}}),(errors.length)?_c('span',{staticClass:"error-message"},[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,false,170533860)})],1)]),(!_vm.isEditMode)?_c('div',{staticClass:"form-group mt-2 mb-0"},[(!_vm.isReadOnly)?_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.editData}},[_c('i',{staticClass:"fa fa-edit"}),_vm._v(" Edit ")]):_vm._e()]):_vm._e(),(_vm.isEditMode)?_c('div',{staticClass:"form-group mt-2 mb-0"},[_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.saveData.apply(null, arguments)}}},[_vm._v(" Save ")]),(_vm.savingData)?_c('b-spinner',{staticClass:"ml-3 align-middle",attrs:{"variant":"primary"}}):_vm._e(),_c('button',{staticClass:"btn btn-secondary ml-3",on:{"click":function($event){$event.preventDefault();return _vm.cancelEditMode.apply(null, arguments)}}},[_vm._v(" Cancel ")])],1):_vm._e()])],1):_vm._e()])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }