<template>
    <div v-if="comparisonData">
        <div class="row">
            <div class="col-12">
                <table class="table table-hover table-bordered table-vertical-center table-sm table-fixed-layout">
                    <thead>
                        <tr>
                            <th class="flex-container-heading" style="height: 50px; background-color: #eff3f6;">PERIOD</th>
                            <th v-for="(item, index) in comparisonData.insurers"
                                class="different-values text-center comparison-data-header" :key="index">
                                {{ item }}
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="item in comparisonData.dates" :key="item.apiKey"
                            :class="{ 'different-values': areValuesDifferent(item.data) }">
                            <td>
                                <div class="flex-container-description bold-label" style="min-height: 65px;">
                                    {{ item.displayName }}
                                </div>
                            </td>
                            <td class="max-width-col" v-for="(col, colIndex) in item.data" :key="colIndex">
                                <div class="flex-container" style="min-height: 65px;">
                                    <label v-if="item.type === 'date' && col" class="col-form-label">{{
                                        col | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY")
                                    }}
                                    </label>
                                    <label v-else-if="col" class="col-form-label">{{ col }} </label>
                                </div>

                            </td>
                        </tr>
                        <tr>
                            <td class="flex-container-heading" style="height: 50px; background-color: #eff3f6;"
                                :colspan="colspan">SUMS
                                INSURED</td>
                        </tr>
                        <tr v-for="(item, index) in comparisonData.sumInsuredData" :key="item.apiKey + '-' + index"
                            :class="{ 'different-values': areValuesDifferent(item.data) }">
                            <td>
                                <div class="flex-container-description bold-label" style="min-height: 65px;">
                                    {{ item.displayName }}
                                </div>
                            </td>

                            <td v-for="(col, colIndex) in item.data" :key="colIndex" class="max-width-col">
                                <div class="flex-container" style="min-height: 65px; position: relative;">
                                    <label v-if="item.type === 'Currency' && col" class="col-form-label">
                                        {{ (col || 0) | currency("$", ",", 0, ".", "front", true) }}
                                    </label>
                                    <label v-else-if="item.type === 'date' && col" class="col-form-label">
                                        {{ col | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
                                    </label>
                                    <label v-else-if="col" class="col-form-label">
                                        {{ (col || '-') }}
                                    </label>
                                    <label v-else class="col-form-label">
                                        -
                                    </label>
                                    <span v-if="colIndex > 0 && shouldShowPercentageChange(item, col)"
                                        class="percentage-change">
                                        {{ calculatePercentageChange(item.data[0], col) }}
                                    </span>
                                </div>
                            </td>

                        </tr>
                        <tr>
                            <td class="flex-container-heading" style="height: 50px; background-color: #eff3f6;"
                                :colspan="colspan">
                                EXCESSES</td>
                        </tr>
                        <tr v-for="(item, index) in comparisonData.excessData" :key="item.apiKey + '-' + index"
                            :class="{ 'different-values': areValuesDifferent(item.data) }">
                            <td>
                                <div class="flex-container-description bold-label" style="min-height: 65px;">
                                    {{ item.displayName }}
                                </div>
                            </td>

                            <td v-for="(col, colIndex) in item.data" :key="colIndex" class="max-width-col">
                                <div class="flex-container" style="min-height: 65px; position: relative;">
                                    <label v-if="item.type === 'Currency' && col" class="col-form-label">
                                        {{ (col || 0) | currency("$", ",", 0, ".", "front", true) }}
                                    </label>
                                    <label v-else-if="item.type === 'date' && col" class="col-form-label">
                                        {{ col | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
                                    </label>
                                    <label v-else-if="col" class="col-form-label">
                                        {{ (col || '-') }}
                                    </label>
                                    <span v-if="colIndex > 0 && shouldShowPercentageChange(item, col)"
                                        class="percentage-change">
                                        {{ calculatePercentageChange(item.data[0], col) }}
                                    </span>
                                </div>
                            </td>


                        </tr>
                        <tr>
                            <td class="flex-container-heading" style="height: 50px; background-color: #eff3f6;"
                                :colspan="colspan">
                                FINANCIALS</td>
                        </tr>
                        <tr v-for="item in comparisonData.financialData" :key="item.apiKey"
                            :class="{ 'different-values': areValuesDifferent(item.data) }">
                            <td :class="{ 'bold-label': isBoldLabel(item.displayName) }">
                                <div class="flex-container-description bold-label"
                                    :style="{ color: determineColor(item.displayName) }" style="min-height: 65px;">
                                    {{ item.displayName }}
                                </div>
                            </td>

                            <td v-for="(col, colIndex) in item.data" :key="colIndex" class="max-width-col">
                                <div class="flex-container" style="min-height: 65px; position: relative;">
                                    <label v-if="item.type === 'currency' || item.type === 'Currency' && col"
                                        class="col-form-label">
                                        {{ (col || 0) | currency("$", ",", 2, ".", "front", true) }}
                                    </label>
                                    <label v-else-if="item.type === 'date' && col" class="col-form-label">
                                        {{ col | dateParse("YYYY-MM-DD") | dateFormat("DD/MM/YYYY") }}
                                    </label>
                                    <label v-else-if="item.type === 'decimal'" class="col-form-label">
                                        {{ (col || 0) }} %
                                    </label>
                                    <label v-else-if="col" class="col-form-label">
                                        {{ (col || '-') }}
                                    </label>
                                    <span v-if="colIndex > 0 && shouldShowPercentageChange(item, col)"
                                        class="percentage-change" v-b-tooltip.hover :title="`vs. recommended`">
                                        {{ calculatePercentageChange(item.data[0], col) }}
                                    </span>
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="mt-10">
            <h4 class="text-center font-weight-bold mb-0">
                Prior Year and Current Year Comparison
            </h4>
            <div class="text-center mt-0 mb-5">
                <small>Based on Recommended Renewal Quote</small>
            </div>
            <div class="row">
                <div class="col-12">
                    <table class="table  table-hover table-vertical-center table-fixed-layout">
                        <tr>
                            <th></th>
                            <th class="text-center">Last Year</th>
                            <th class="text-center">Current Year</th>
                            <th class="text-center">Percent Change</th>
                        </tr>
                        <tr v-for="(item, index) in historicalData" :key="index">
                            <td class="pl-6" style="font-weight: 700;">{{ item.displayName }}</td>
                            <td v-for="(dataValue, colIndex) in item.data" :key="colIndex" class="pl-10 pr-10">
                                <span v-if="colIndex < 2">
                                    <ValidationProvider rules="required" :name="`${item.displayName}${colIndex}`"
                                        v-slot="{ errors }">
                                        <currency-input class="form-control text-right pr-10" v-model="item.data[colIndex]"
                                            @input="valueChanged(item)" :allow-negative="true" :distraction-free="false"
                                            :precision="item.displayName === 'Building Sum Insured' ? 0 : 2
                                                " :disabled="isReadOnly" />
                                        <span class="text-danger">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </span>
                                <div class="input-group" v-if="colIndex === 2">
                                    <input type="text" class="form-control text-right pr-10"
                                        :value="formatNumber(item.data[colIndex])" readonly />
                                    <div class="input-group-append">
                                        <span class="input-group-text">%</span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";

export default {
    name: "ComparisonTable",

    props: {
        comparisonData: Object,
        isReadOnly: Boolean,
    },

    data() {
        return {};
    },

    /** Every time comparisonData reloads and internal historicalData changes, recalculate the % */
    watch: {
        historicalData: function (newHistoricalData /*oldHistoricalData*/) {
            this.recalculateHistoricalDataPercent(newHistoricalData);
        },
    },

    mounted() {
        this.recalculateHistoricalDataPercent(this.historicalData);

        /** recalculate historic data */
        // this.historicalData.forEach(item => {
        //   this.valueChanged(item);
        // });
    },

    computed: {
        colspan: function () {
            if (this.comparisonData && Array.isArray(this.comparisonData.insurers)) {
                return this.comparisonData.insurers.length + 1;
            }
            return 1;
        },

        historicalData: function () {
            return this.comparisonData.historicalData;
        },
    },

    methods: {
        shouldShowPercentageChange(item, value) {
            // Don't show percentage change for text values or commission type
            if (item.displayName === 'Commission Type' ||
                typeof value === 'string' && isNaN(value)) {
                return false;
            }
            return this.areValuesDifferent(item.data);
        },
        areValuesDifferent: function (data) {
            if (Array.isArray(data) && data.length > 0) {
                // Don't compare if first value is text
                if (typeof data[0] === 'string' && isNaN(data[0])) {
                    return false;
                }
                // Convert all values to numbers, treating empty/null as 0
                const normalizedData = data.map(val => {
                    if (typeof val === 'string' && isNaN(val)) return val;
                    return Number(val || 0);
                });
                return _.some(normalizedData, (val) => val !== normalizedData[0]);
            }
            return false;
        },

        displayValue: function (value, type) {
            if (value === null || value === undefined || value === '') {
                value = 0;
            }

            switch (type) {
                case 'currency':
                    return this.$options.filters.currency(value || 0, "$", ",", 2, ".", "front", true);
                case 'date':
                    return value ? this.$options.filters.dateFormat(this.$options.filters.dateParse(value, "YYYY-MM-DD"), "DD/MM/YYYY") : '-';
                case 'decimal':
                    return `${Number(value || 0)} %`;
                default:
                    return value || '0';
            }
        },

        formatNumber: function (value) {
            // Handle empty/null values
            if (value === null || value === undefined || value === '') {
                return '0';
            }
            return isNaN(value) ? '0' : Number(value).toFixed(0);
        },

        determineColor(displayName) {
            if (displayName.startsWith('CIB')) {
                return 'navy';
            } else if (displayName.startsWith('AR')) {
                return 'red';
            } else {
                return 'inherit'; // Default color
            }
        },

        calculatePercentageChange: function (baseValue, compareValue) {
            // Don't calculate for non-numeric values
            if (typeof baseValue === 'string' && isNaN(baseValue) ||
                typeof compareValue === 'string' && isNaN(compareValue)) {
                return '';
            }

            // Convert empty/null values to 0
            baseValue = Number(baseValue || 0);
            compareValue = Number(compareValue || 0);

            if (baseValue === 0 && compareValue === 0) {
                return '';
            }

            if (baseValue === 0) {
                return compareValue !== 0 ? 'N/A' : '';
            }

            let percentageChange = ((compareValue - baseValue) / baseValue) * 100;

            if (percentageChange === 0) {
                return '';
            }

            let formattedChange = percentageChange.toFixed(2) + '%';
            return percentageChange >= 0 ? '+' + formattedChange : formattedChange;
        },

        isBoldLabel: function (label) {
            return (
                label === "Total Insurer Payable" || label === "Total Premium Payable"
            );
        },

        valueChanged(historyItem) {
            let priorYearValue = Number(historyItem.data[0]) || 0;
            let currentYearValue = Number(historyItem.data[1]) || 0;

            let percentChange = 0;
            if (priorYearValue === 0 && currentYearValue === 0) {
                percentChange = 0;
            } else if (priorYearValue === 0) {
                percentChange = currentYearValue > 0 ? 100 : 0;
            } else {
                percentChange = ((currentYearValue - priorYearValue) / priorYearValue) * 100;
                percentChange = this.round(percentChange);
            }

            this.$set(historyItem.data, 2, percentChange);
        },

        recalculateHistoricalDataPercent: function (historicalData) {
            if (Array.isArray(historicalData)) {
                for (let hData of historicalData) {
                    this.valueChanged(hData);
                }
            }
        },

        calculatePercent: function (value, parentValue) {
            if (!_.isNil(value) && !_.isNil(parentValue)) {
                return this.round((Number(value) * 100) / Number(parentValue)) || 0;
            }
            return 0;
        },

        calculatePercentAmount: function (currencyValue, percent) {
            if (!_.isNil(currencyValue) && !_.isNil(percent)) {
                return this.round((Number(currencyValue) * Number(percent)) / 100) || 0;
            }
            return 0;
        },

        round: function (number) {
            if (!_.isNil(number)) {
                return Math.round(Number(number) * 100 + Number.EPSILON) / 100 || 0;
            }
            return 0;
        },
    },
};
</script>

<style scoped>
.different-values {
    background-color: #ffebcc;
}

.flex-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-width: 100px;
    padding-right: 35%;
}

.flex-container-description {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 100px;
    padding-left: 18px !important;
}

.flex-container-heading {
    justify-content: left;
    padding-left: 18px !important;
    color: rgb(99, 99, 187);
    font-weight: bold;
}

.bold-label {
    font-weight: bold;
}

.max-width-col {
    max-width: 200px !important;
    overflow: hidden;
    text-align: right !important;
    text-overflow: ellipsis;
    font-weight: 500;
}

.table-fixed-layout {
    table-layout: fixed;
}

.comparison-data-header {
    font-size: larger;
}

.percentage-change {
    display: block;
    /* Put percentage change on a new line */
}

.flex-container {
    position: relative;
    /* Needed for absolute positioning inside */
    text-align: right;
    /* Keep numeric values right-aligned */
}

.percentage-change {
    position: absolute;
    right: 10px;
    /* Align to the right edge of the cell */
    top: 50%;
    /* Center vertically */
    transform: translateY(-50%);
    /* Adjust for exact vertical centering */
    white-space: nowrap;
    /* Prevent wrapping */
    margin-left: 10px;
    /* Space between the number and the percentage */
    color: gray;
    font-size: xx-small;
}
</style>
